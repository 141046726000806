import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";
import { GTMAnalyticsGenerateData } from "./pyw_GTM_Analytics_Helper";

const gtmUrl = `https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_PYW_GTM_ID}&gtm_auth=${process.env.REACT_APP_PYW_GTM_AUTH}&gtm_preview=${process.env.REACT_APP_PYW_GTM_PREVIEW}&tm_cookies_win=x&`;
const analytics = Analytics({
	app: "awesome-app",
	plugins: [
		googleTagManager({
			containerId: process.env.REACT_APP_PYW_GTM_ID,
			customScriptSrc: gtmUrl,
		}),
	],
});

function trackPageView(data, event, flag) {
	const GTM_DATA = GTMAnalyticsGenerateData(data, event);

	analytics?.page({
		...GTM_DATA,
	});
}

function trackEvent(event, data) {
	const GTM_DATA = GTMAnalyticsGenerateData(data);
}

export function GTMAnalytics(triggerType, event, data) {
	switch (triggerType) {
		case "page":
			return trackPageView(data, event);
		case "event":
			return trackEvent(event, data);
		default:
			return;
	}
}
