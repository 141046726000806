import React from "react";
import cookie from "react-cookies";
import '../assets/styles/css/main.css';
//import { fetchPrepareDetails } from "../redux";
import { useSelector} from "react-redux";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";
const PywError = (props) => {

    const pageData = useSelector(state => state.pageData);
    return (
        <main className="error-width">
            {/* <PywTealiumAnalytics response={pageData} tealiumEvent="checkout" /> */}
            <AnalyticsWrapper
				 
				triggerType="page"
				event="checkout"
                data={pageData}
			/>
            <div>
                <div  class="flex center mt-16">
                    <a href= "mailto:Sywsolutions@syw.com"class="red mt-16"><b>There seems to be a system issue.<br/>Please contact our Member Services Team for assistance.</b></a>
                </div>
                <div class="mt-32 flex center">
                {(() => {
                    if ((window.opener && window.opener !== window && !window.menubar.visible) === null ) {
                        return <p><div class="close_msg dark-grey"><svg className="mr-8" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="2em" width="2em" ><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path></svg> You may close the page</div></p>
                        } else {
                            return  <button className="btn btn-primary" onClick={() => {window.close()}}>
                            {cookie.load("merchantName")===undefined ||cookie.load("merchantName")==='' ?"Close":"Back to "+cookie.load("merchantName") }
                            </button>
                        }
                    })()}    
                </div>
            </div> 
        </main>
    );
}

export default PywError;
