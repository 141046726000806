export const FETCH_PREPARE_REQUEST = 'FETCH_PREPARE_REQUEST'
export const FETCH_PREPARE_SUCCESS = 'FETCH_PREPARE_SUCCESS'
export const FETCH_PREPARE_FAILURE = 'FETCH_PREPARE_FAILURE'

export const TRANSFER_CART_REQUEST = 'TRANSFER_CART_REQUEST'
export const TRANSFER_CART_SUCCESS = 'TRANSFER_CART_SUCCESS'
export const TRANSFER_CART_FAILURE = 'TRANSFER_CART_FAILURE'

export const ORDER_CONFIRMATION_REQUEST = 'ORDER_CONFIRMATION_REQUEST'
export const ORDER_CONFIRMATION_SUCCESS = 'ORDER_CONFIRMATION_SUCCESS'
export const ORDER_CONFIRMATION_FAILURE = 'ORDER_CONFIRMATION_FAILURE'
export const ORDER_CONFIRMATION_LIGHT_SUCCESS = "ORDER_CONFIRMATION_LIGHT_SUCCESS"
export const FETCH_ORDERCONFIRMATION_SUCCESS_ERROR = 'FETCH_ORDERCONFIRMATION_SUCCESS_ERROR'

export const FETCH_APPLYPOINTS_REQUEST = 'FETCH_APPLYPOINTS_REQUEST'
export const FETCH_APPLYPOINTS_SUCCESS = 'FETCH_APPLYPOINTS_SUCCESS'
export const FETCH_APPLYPOINTS_SUCCESS_ERROR = 'FETCH_APPLYPOINTS_SUCCESS_ERROR'
export const FETCH_APPLYPOINTS_FAILURE = 'FETCH_APPLYPOINTS_FAILURE'



export const FETCH_PLACEORDER_REQUEST = 'FETCH_PLACEORDER_REQUEST'
export const FETCH_PLACEORDER_SUCCESS = 'FETCH_PLACEORDER_SUCCESS'
export const FETCH_PLACEORDER_SUCCESS_ERROR = 'FETCH_PLACEORDER_SUCCESS_ERROR'
export const FETCH_PLACEORDER_FAILURE = 'FETCH_PLACEORDER_FAILURE'


export const FETCH_CREDITCARD_REQUEST = 'FETCH_CREDITCARD_REQUEST'
export const FETCH_CREDITCARD_SUCCESS = 'FETCH_CREDITCARD_SUCCESS'
export const FETCH_CREDITCARD_SUCCESS_ERROR = 'FETCH_CREDITCARD_SUCCESS_ERROR'
export const FETCH_CREDITCARD_FAILURE = 'FETCH_CREDITCARD_FAILURE'

export const ANALYTICS_PREPARECHECKOUT_ERROR_CODE = '1'
export const ANALYTICS_APPLYPOINTS_ERROR_CODE = '2'
export const ANALYTICS_CREDITCARD_ERROR_CODE = '3'
export const ANALYTICS_PLACEORDER_ERROR_CODE = '4'
export const ANALYTICS_TRANSFER_ERROR_CODE = '5'

export const ADD_SUBSCRIPTION_REQUEST = 'ADD_SUBSCRIPTION_REQUEST'
export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS'
export const ADD_SUBSCRIPTION_SUCCESS_ERROR = 'ADD_SUBSCRIPTION_SUCCESS_ERROR'
export const ADD_SUBSCRIPTION_FAILURE = 'ADD_SUBSCRIPTION_FAILURE'

export const GENERATE_PAYMENT_REQUEST = 'GENERATE_PAYMENT_REQUEST'
export const GENERATE_PAYMENT_SUCCESS = 'GENERATE_PAYMENT_SUCCESS'
export const GENERATE_PAYMENT_SUCCESS_ERROR = 'GENERATE_PAYMENT_SUCCESS_ERROR'
export const GENERATE_PAYMENT_FAILURE = 'GENERATE_PAYMENT_FAILURE'


export const GET_SDKCONTENT_REQUEST = 'GET_SDKCONTENT_REQUEST'
export const GET_SDKCONTENT_SUCCESS = 'GET_SDKCONTENT_SUCCESS'
export const GET_SDKCONTENT_SUCCESS_ERROR = 'GET_SDKCONTENT_SUCCESS_ERROR'
export const GET_SDKCONTENT_FAILURE = 'GET_SDKCONTENT_FAILURE'

export const VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST'
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS'
export const VALIDATE_EMAIL_SUCCESS_ERROR = 'VALIDATE_EMAIL_SUCCESS_ERROR'
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE'

export const DISABLE_BUTTON_CLICK_TRUE = 'DISABLE_BUTTON_CLICK_TRUE'
export const ENABLE_BUTTON_CLICK_TRUE = 'ENABLE_BUTTON_CLICK_TRUE'

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_SUCCESS_ERROR = 'VERIFY_EMAIL_SUCCESS_ERROR'
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE'

export const ENABLE_INPUTS = 'ENABLE_INPUTS'
export const DISABLE_INPUTS = 'DISABLE_INPUTS'

