import {
    FETCH_MERCHANT_DETAIL_REQUEST,
    FETCH_MERCHANT_DETAIL_SUCCESS,
    FETCH_MERCHANT_DETAIL_FAILURE,
    FETCH_MERCHANT_DETAIL_SUCCESS_ERROR,
    FETCH_MEMBER_DETAIL_REQUEST,
    FETCH_MEMBER_DETAIL_SUCCESS,
    FETCH_MEMBER_DETAIL_FAILURE,
    FETCH_MEMBER_DETAIL_SUCCESS_ERROR

} from './pywLearnMoreDetailsTypes';

const initialState = {
    pageLoading: false,
    merchantDetailResponse: '',
    apiError: false,
    apiFailure: false,
    isValid: false,
    pageError: '',
    memberDetailsResponse: '',
    merchantName:'',
	memberNumber:'',
    emailHash:'',
	sywrUserId:'',
    showLoading:false,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_MERCHANT_DETAIL_REQUEST:
            return {
                ...state,
                pageLoading: true
            }
        case FETCH_MERCHANT_DETAIL_SUCCESS:
            return {
                ...state,
                pageLoading: false,
                merchantName:action.payload.merchantDetails.merchantName,
                merchantDetailResponse: action.payload,
                pageError: ''
            }
        case FETCH_MERCHANT_DETAIL_FAILURE:
            return {
                pageLoading: false,
                apiError: true,
                apiFailure: true,
                pageError: action.payload
            }
        case FETCH_MERCHANT_DETAIL_SUCCESS_ERROR:
            return {
                pageLoading: false,
                apiError: true,
                apiFailure: false,
                pageError: action.payload
            }
        case FETCH_MEMBER_DETAIL_REQUEST:
            return {
                ...state,
                pageLoading: true,
                showLoading:true,
            }
        case FETCH_MEMBER_DETAIL_SUCCESS:
            return {
                ...state,
                pageLoading: false,
                merchantName:action.payload.merchantDetails.merchantName,
                memberNumber:action.payload.memberDetails?.memberNumber,
                emailHash:action.payload.emailAddress,
                sywrUserId:action.payload.memberDetails?.name,
                memberDetailsResponse: action.payload,
                pageError: '' ,
                showLoading:true,
            }
        case FETCH_MEMBER_DETAIL_FAILURE:
            return {
                pageLoading: false,
                apiError: true,
                apiFailure: true,
                pageError: action.payload,
                showLoading:false,
            }
        case FETCH_MEMBER_DETAIL_SUCCESS_ERROR:
            return {
                pageLoading: false,
                apiError: true,
                apiFailure: false,
                pageError: action.payload,
                showLoading:false,
            }
        default:
            return state
    }
}

export default reducer