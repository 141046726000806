export function utag_checkout_var(response) {

  var checkout_data = {
  }

  //var utag_cartId = cart.cartId;
  var itemIds = [];
  var itemNames = [];
  var itemPrices = [];
  var itemQty = [];
  var position = [];
  if (response !== undefined && response.items !== undefined) {
    var items = response.items;
    for (const key in items) {
      position.push(Number(key) + 1)
      for (let item in items[key]) {
        if (item === "itemId") {
          itemIds.push(items[key][item])
        }
        if (item === "itemName") {
          itemNames.push(items[key][item])
        }
        if (item === "salePrice") {
          itemPrices.push(items[key][item])
        }
        if (item === "quantity") {
          itemQty.push(items[key][item])
        }
      }
    }
  }

  var errors = "";
  var errorEventAction = "";
  var errorEventLabel = "";
  if (response !== undefined && response !== null) {
    if (response.pageError !== undefined && response.pageError !== "" && response.pageError.errors instanceof Array) {
      response.pageError.errors.map((error) => errors = error.message);
      errorEventAction = "pyw error";
      errorEventLabel = "system error";
  } else if (response.pageApiError instanceof Array) {
      response.pageApiError.map((error) => errors = error.message);
    }
    var apiErrorCode = response.analyticsAPIErrorCode;
    if (apiErrorCode !== undefined && apiErrorCode !== null) {
      if (apiErrorCode === "1") {
        errorEventAction = "pyw error";
        errorEventLabel = "system error";
        response.pageError.errors.map((error) => errors = error.message);
      } else if (apiErrorCode === "2") {
        errorEventAction = "applynow points click";
        if ("Insufficient Points Balance" === errors) {
          errorEventLabel = "insufficient funds"
        } else {
          errorEventLabel = "failed to apply points"
        }
      } else if (apiErrorCode === "3") {
        errorEventAction = "place order click";
        errorEventLabel = "invalid card"
      } else if (apiErrorCode === "4") {
        errorEventAction = "place order click";
        if ("CreditCard Authorization Failed" === errors) {
          errorEventLabel = "authentication failure"
        } else {
          errorEventLabel = "Failed to place order"
        }
      }
    }
  }

  var checkout_events_details = {}
  if ("" !== errors) {
    checkout_events_details = {
      "eventCategory": "errors",
      "eventAction": errorEventAction,
      "eventLabel": errorEventLabel,
      "eventName": 'pyw checkout Error',
      "event_name": 'pyw checkout Error'
    }
  } else {
    checkout_events_details = {
      "eventCategory": 'Checkout',
      "eventAction": 'Checkout click',
      "eventLabel": "Checkout",
      "eventName": 'Checkout',
      "event_name": 'Checkout',
      'eventValue': 'Checkout View'
    }
  }

  var is5321CardHolder = check5321CardHolder(response);
  checkout_data = {
    'tealium_event': 'checkout',
    'errorMessage': errors,
    'item_id': itemIds,
    'item_name': itemNames,
    'item_brand': [],
    'item_variant': [],
    'item_category': [],
    'price': itemPrices,
    'quantity': itemQty,
    'product_unit_price': itemPrices,
    'product_position': position,
    '5321CardHolder': is5321CardHolder,
    'checkout_step': '1'
  }

  checkout_data = Object.assign(checkout_data, checkout_events_details)
  return checkout_data;

}

function check5321CardHolder(data) {
  var is5321CardHolder = "no";
  if (data !== undefined && data.tenders instanceof Array) {
    data.tenders.map((tender) => {
      if ("CC_DC" === tender.tenderType) {
        if (tender.savedCards !== undefined && tender.savedCards instanceof Array && tender.savedCards.length > 0) {
          is5321CardHolder = "yes";
        }
      }
    })
  }
  return is5321CardHolder;
}