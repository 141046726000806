import {
  FETCH_WEB_SOCKET_REQUEST,
  FETCH_WEB_SOCKET_SUCCESS,
  FETCH_WEB_SOCKET_FAILURE,
  FETCH_WEB_SOCKET_DISCONNECT,
  FETCH_WEB_SOCKET_CUSTOM_CLOSE,
  FETCH_WEB_SOCKET_AUTO_CLOSE,
} from "./pywWebSocketTypes";

export const fetchWebSocketDetails = (WS_PARAMS) => {
  return (dispatch) => {
    const ws = new WebSocket(WS_PARAMS.url);
    var startTime = new Date();
    ws.onopen = () => {
      dispatch(fetchWebSocketRequest());
      if (WS_PARAMS.timeoutRequire) {
        setTimeout(() => {
          ws.onclose({ customClose: "false", hsCartStatus: "cart" });
        }, WS_PARAMS.timeout);
      }
      if (WS_PARAMS.timeoutRequire && WS_PARAMS.hsCall) {
        setTimeout(() => {
          ws.onclose({ customClose: "true", hsCartStatus: "cart" });
        }, WS_PARAMS.timeout);
      }
      if (WS_PARAMS.hsConfirmTimeoutRequire && WS_PARAMS.hsCall) {
        setTimeout(() => {
          ws.onclose({ customClose: "true", hsCartStatus: "interim-order" });
        }, WS_PARAMS.hsConfirmTimeout);
      }
      var elapsedTime = new Date() - startTime;
      console.log(
        "WebSocket Connection Established, timetaken: ",
        elapsedTime,
        " MILLISECONDS"
      );
      console.log("Inside onopen() method...");

      ws.onmessage = (resp) => {
        console.log("Inside onmessage() method... success");
        console.log(resp);
        if(JSON.parse(resp.data).message!== "Connection is active"){
          dispatch(fetchWebSocketSuccess(JSON.parse(resp.data)));
        }
        // dispatch(fetchWebSocketSuccess(JSON.parse(resp.data)));
        // setServerResponse(JSON.parse(servermsg.data).message);
      };
      ws.onerror = (err) => {
        console.log("Inside onerror() method... failure");
        dispatch(fetchWebSocketFailure(err));
        console.error(err);
      };
      ws.onclose = (res) => {
        console.log("Closing connection....", res);
        if (res.customClose === "true") {
          dispatch(fetchWebSocketCustomClose(res));
        } else {
          const autoRes = {
            isAutoClose: "true",
            dataResp: res
          };
          dispatch(fetchWebSocketAutoClose(autoRes));
        }

        // console.error(res);
      };
    };
  };
};

export const fetchWebSocketRequest = () => {
  return {
    type: FETCH_WEB_SOCKET_REQUEST,
  };
};

export const fetchWebSocketSuccess = (apiData) => {
  return {
    type: FETCH_WEB_SOCKET_SUCCESS,
    payload: apiData,
  };
};

export const fetchWebSocketFailure = (error) => {
  return {
    type: FETCH_WEB_SOCKET_FAILURE,
    payload: error,
  };
};

export const fetchWebSocketDisconnect = () => {
  return {
    type: FETCH_WEB_SOCKET_DISCONNECT,
  };
};

export const fetchWebSocketCustomClose = (apiData) => {
  return {
    type: FETCH_WEB_SOCKET_CUSTOM_CLOSE,
    payload: apiData,
  };
};
export const fetchWebSocketAutoClose = (apiData) => {
  return {
    type: FETCH_WEB_SOCKET_AUTO_CLOSE,
    payload: apiData,
  };
};
