import { images } from "../../pywImagesComponent/PywImages"
import { headerPyw } from "../../pywConstantComponent/PywGuestDesignConstant"

const PywValidationComponent = () => {
    return (
        <main className="unifymoney-flex-container flex-container gotham-font-style">
            <div className="width-495">
                {headerPyw()}
                <section className="flex-center-column">
                    <div className='unifymoney-validation-loader width-343'>
                        <div className="unifymoney-validation-loader__image">
                            <img src={images.loadingCard} alt="Loading" />
                        </div>
                        <div className="unifymoney-validation-loader__message">
                            <p>Validating Payment...</p>
                        </div>
                        <div className="unifymoney-validation-loader__footer">
                            <p><img src={images.lock} alt="" /> <span>Powered by Shop Your Way</span></p>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    )
}

export default PywValidationComponent