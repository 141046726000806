import React from "react";
import cookie from "react-cookies";

var childwin = null;
var intervalID = null;
export function handleShow(){return true}
export function openPopup(citiRdo) {
  if (childwin == null || childwin.closed) {
    cookie.save('_selfpopup', true, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
    const windowHeight = window.parent.innerHeight;
		const windowWidth = window.parent.innerWidth;
		const popUpHeight = 670;
		const popUpWidth = windowWidth > 780 ? 780 : windowWidth;
		const left = windowWidth > 780 ? (windowWidth - popUpWidth) / 2 : 0;
		const top = (windowHeight - popUpHeight) / 2;
    let params = 'resizable=no,status=no,location=no,toolbar=no,menubar=no,width=' + popUpWidth + ',height=' + popUpHeight + ', top=' + top + ', left=' + left + ' ';
     document.body.style.cursor= "wait";

    //var openUrl = window.location.origin+ "/citiredirection?citiRandom="+citiRdom+"&flag="+flag;
    setInterval(parentDisable,10);
    childwin = window.open(process.env.REACT_APP_CITI_CHECKOUT_URL + "flag=2&citiRandom=" + citiRdo+"&cartId="+sessionStorage.getItem("pywcartId")+"&refid="+sessionStorage.getItem("pywrefid")+"&merchant="+sessionStorage.getItem("pywmerchant"), "PYWPay", params);
   
  } else {
    
  }
 intervalID = setInterval(reloadParentWin, 100);
}

function reloadParentWin() {
  if (childwin == null || childwin.closed) {
    if(cookie.load("_popupflag")==="true"){
      var winItervalID= setInterval(window.location.replace(window.location.origin+"/pyw/citi/return"), 0);
    }else{
      var winItervalID= setInterval(window.location.reload(), 0);
    }
   
     clearInterval(winItervalID);
      clearInterval(intervalID);
  }
}

function parentDisable() {
  if (childwin != null && !childwin.closed) {
    childwin.focus();
  }
}
