import {
  FETCH_WEB_SOCKET_REQUEST,
  FETCH_WEB_SOCKET_SUCCESS,
  FETCH_WEB_SOCKET_FAILURE,
  FETCH_WEB_SOCKET_DISCONNECT,
  FETCH_WEB_SOCKET_CUSTOM_CLOSE,
  FETCH_WEB_SOCKET_AUTO_CLOSE,
} from "./pywWebSocketTypes";

const initialState = {
  pageLoading: false,
  apiError: false,
  apiDisconnected: false,
  wsResponse: "",
  pageError: "",
  isValid: false,
  confirmStatus: "",
  customDisconnect: "",
  autoClose: "",
  iscolse:false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEB_SOCKET_REQUEST:
      return {
        ...state,
        pageLoading: true,
        apiDisconnected: false,
        apiError: false,
        pageError: "",
        isValid: false,
      };
    case FETCH_WEB_SOCKET_SUCCESS:
      return {
        ...state,
        pageLoading: false,
        wsResponse: action.payload,
        confirmStatus: action.payload.status,
        memberNumber: action.payload.memberNumber,
        apiDisconnected: false,
        apiError: false,
        pageError: "",
        isValid: true,
      };
    case FETCH_WEB_SOCKET_FAILURE:
      return {
        ...state,
        pageLoading: false,
        apiError: true,
        apiDisconnected: false,
        pageError: action.payload,
        isValid: true,
      };
    case FETCH_WEB_SOCKET_DISCONNECT:
      return {
        ...state,
        pageLoading: false,
        apiError: false,
        apiDisconnected: true,
        pageError: "",
        isValid: true,
        iscolse:true
      };
    case FETCH_WEB_SOCKET_CUSTOM_CLOSE:
      return {
        ...state,
        pageLoading: false,
        apiError: false,
        apiDisconnected: true,
        pageError: "",
        isValid: true,
        customDisconnect: action.payload,
        iscolse:true
      };
      case FETCH_WEB_SOCKET_AUTO_CLOSE:
        return {
          ...state,
          pageLoading: false,
          apiError: false,
          apiDisconnected: true,
          pageError: "",
          isValid: true,
          autoClose: action.payload,
          iscolse:true
        };
    default:
      return state;
  }
};

export default reducer;
