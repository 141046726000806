import React from "react";
import history from "../pywUtilComponent/history";
import { Auth0Provider } from "@auth0/auth0-react";

const PywAuth0ProviderWithHistory = ({ children }) => {
    const onRedirectCallback = (appState) => {
        history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
    };
    const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
    redirectUri: process.env.REACT_APP_AUTH0_CALLBACK,
    onRedirectCallback,
    useRefreshTokens: true,
    cacheLocation: "localstorage"
    };

  return (
    <Auth0Provider  {...providerConfig}>{ children }
    </Auth0Provider>
  );
};

export default PywAuth0ProviderWithHistory;
