import React from "react";


var childwin = null;
var intervalID = null;
export function handleShow(){return true}
export function openPopup(e) {
  if (childwin == null || childwin.closed) {
    var popupWinWidth=520;
    var popupWinHeight=600;
    var winLeft = (window.screen.width - popupWinWidth) / 2;
    var winTop = (window.screen.height - popupWinHeight) / 4;
    var params =
      "left=" + winLeft +",top=" + winTop+100 +
      ",width="+popupWinWidth+",height="+popupWinHeight+",menubar=0,status=0,titlebar=0,scrollbars=1,resizable=0";
     document.body.style.cursor= "wait";

    var openUrl = e===undefined || e===''?process.env.REACT_APP_CITI_CARD_SEE_DETAILS_URL+"p=PYW#terms":e;
    setInterval(parentDisable,10);
    childwin = window.open(openUrl, "PYWPay", params);
   
  } else {
    
  }
 intervalID = setInterval(reloadParentWin, 100);
}

function reloadParentWin() {
  if (childwin == null || childwin.closed) {
     document.body.style.cursor= "default";
      clearInterval(intervalID);
  }
}

function parentDisable() {
  if (childwin != null && !childwin.closed) {
    childwin.focus();
  }
}
