import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { loading } from "../pywConstantComponent/PywDesignsConstant";
const PywProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () =>loading(),
    })}
    {...args}
  />
);

export default PywProtectedRoute;
