import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  headers,
  PYWCITIRETURN,
  PYW_CITI_CARD_DECLINED,
  PYW_CUSTOM_SUMMARY,
  PYW_GUEST_SUMMARY_PATH,
  PYW_SUMMARY,
  USER_STATE_AUTH,
} from "../../pywConstantComponent/PywConstant";
import {
  loading,
  webSocketLoading,
} from "../../pywConstantComponent/PywDesignsConstant";
import PywError from "../../pywErrorComponent/PywError";
import { fetchPrepareDetails, fetchWebSocketDetails } from "../../redux";
import * as duui from "device-uuid";
import { WS_PARAMS } from "../../pywConstantComponent/pywWebSocketConstant";
import cookie from "react-cookies";
const PywReturnFromCityComponent = (props) => {
  const pageData = useSelector((state) => state.pageData);
  const wspageData = useSelector((state) => state.wsPageData);
  const dispatch = useDispatch();
  const { user, isAuthenticated, logout } = useAuth0();
  const [isPrepareCalled, setIsPrepareCalled] = useState(0);

  const webSocketCall = () => {
    WS_PARAMS.url =
      process.env.REACT_APP_SOCKET_URL +
      "?s=pyw&id=" +
      sessionStorage.getItem("pywcartId");
    WS_PARAMS.timeout = process.env.REACT_APP_WS_CITI_RETURN_TIMEOUT;
    WS_PARAMS.timeoutRequire = true;
    dispatch(fetchWebSocketDetails(WS_PARAMS));
    setIsPrepareCalled(0)
  };

  const getfetchPrepareDetails = (prepareType) => {
    const queryString = require("query-string");
    const values = queryString.parse(props.location.search);
    const refid = values.refid;
    // const refid =  window.opener === null ? encodeURIComponent(values.refid) : values.refid;
    const items = [values];
    let requestBody = { items };
    const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW",
    };
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.refid = sessionStorage.getItem("pywrefid");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");

    headers.pywstate =
      PYWCITIRETURN +
      "~~" +
      sessionStorage.getItem("at0pyw") +
      duui.DeviceUUID().get();
    const requestHeaders = {
      headers,
    };
    dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
    setIsPrepareCalled(prepareType);
  };

  useEffect(() => {
    cookie.save("_popupflag", "true", {
      maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE),
      domain: ".shopyourway.com",
      path: "/",
    });
    if (window.opener && !window.opener.closed && cookie.load("_selfpopup")) {
      cookie.save("_selfpopup", false, {
        maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE),
        domain: ".shopyourway.com",
        path: "/",
      });
      cookie.remove("_selfpopup");
      window.close();
    }
    getfetchPrepareDetails(1);
  }, []);

  if (isPrepareCalled === 1) {
    if (pageData.pageLoading) {
      return loading();
    } else if (pageData.isValid) {
      if (
        !(pageData.cartResponseData.creditCardApplicationStatus)
      ) {
        webSocketCall();
      }
      else {
        if (pageData.cartResponseData.userState === USER_STATE_AUTH) {
            if (isAuthenticated) {
                return <Redirect to={PYW_SUMMARY} push={true} />;
            } else {
              // sessionStorage.removeItem('pywcartId');
              // sessionStorage.removeItem('pywrefid');
              return <Redirect to="/pyw/error" push={true} />;
            }
        } else {
            return <Redirect to={PYW_GUEST_SUMMARY_PATH} push={true} />;
        }
      }
    }
  }
  else if (wspageData.pageLoading) {
    return webSocketLoading();
  } else if (wspageData.isValid) {
    if (isPrepareCalled) getfetchPrepareDetails(2);
    if (pageData.pageError) {
      return <PywError pageData={pageData} />;
    } else if (pageData.pageLoading) {
      return loading();
    } else if (pageData.isValid) {
      if (pageData.cartResponseData.userState === USER_STATE_AUTH) {
        if (isAuthenticated) {
          if (
            pageData.ccDc.savedCards == undefined ||
            pageData.ccDc.savedCards.length <= 0
          ) {
            return <Redirect to={PYW_CUSTOM_SUMMARY} push={true} />;
          } else {
            return <Redirect to={PYW_SUMMARY} push={true} />;
          }
        } else {
          // sessionStorage.removeItem('pywcartId');
          // sessionStorage.removeItem('pywrefid');
          return <Redirect to="/pyw/error" push={true} />;
        }
      } else {
        if (wspageData.cardDeclined === "Y") {
          return <Redirect to={PYW_CITI_CARD_DECLINED} push={true} />;
        } else {
          return <Redirect to={PYW_GUEST_SUMMARY_PATH} push={true} />;
        }
      }
    }
    return loading();
  } else {
    console.log("WS Error", wspageData.payload);
    return loading();
  }
};

export default PywReturnFromCityComponent;
