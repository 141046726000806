import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import cookie from "react-cookies";
import { generatePaymentId, getSdkContents } from "../redux";
import { Redirect } from "react-router-dom";
import { loading } from "../pywConstantComponent/PywDesignsConstant"
import PywError from "../pywErrorComponent/PywError";

const Pyw_ECM = (props) => {
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const pageData = useSelector(state => state.pageData);

  useEffect(() => {
    const queryString = require("query-string");
    const values = queryString.parse(props.location.search);

    const merchantClientId = values.merchant;
    const totalDue = values.totalDue;
    const postbackUrl = values.postbackUrl;
    const transactionId = values.txnid
    const refid = values.refid

    let headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      platform: "PYW",
    };

    headers.merchantClientId = merchantClientId;
    headers.transactionId = transactionId;
    headers.refid = refid;

    const requestHeaders = { headers };

    cookie.save('isECM', 'Y',
      { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
    cookie.save('pywmerchant', merchantClientId,
      { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
    cookie.save('merchantName', merchantClientId,
      { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });

    cookie.save('pywrefid', refid,
      { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });

    let requestBody = {};
    requestBody.totalDue = totalDue;
    requestBody.postbackUrl = postbackUrl;

    dispatch(generatePaymentId(requestBody, requestHeaders))
  }, []);


  if (pageData.isValid) {
    cookie.save('pywid', pageData.pywid, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
    return <Redirect to={`/shsdesc?id=${pageData.pmtid}`} />;
  } else if (pageData.pageError) {
    alert("Invalid Payment Details or Invalid Session.");
    return <PywError pageData={pageData} />;
  } else if (pageData.pageLoading) {
    return (loading())
  } else {
    return (<div />)
  }
}

export default Pyw_ECM;
