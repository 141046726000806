import React, { useState, useEffect } from "react";
import { images } from '../../pywImagesComponent/PywImages';
import PywError from "../../pywErrorComponent/PywError";
import { headers, PYWPOINTS, TRUE } from "../../pywConstantComponent/PywConstant"
import cookie from "react-cookies";
import { useSelector, useDispatch } from "react-redux";
import { fetchPrepareDetails, fetchApplyPoints } from "../../redux";
import * as duui from 'device-uuid'
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
import { useAuth0 } from "@auth0/auth0-react";
import { headerPyw, headerUserWithPoints, loading } from "../../pywConstantComponent/PywGuestDesignConstant";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
import { GTMAnalytics } from "../../pywAnalytics/pywGTMAnalytics/Pyw_GTM_Analytics";
const PywApplyPoints = (props) => {
  const { user } = useAuth0();
  const [userInfo, setUserInfo] = useState("");
  const pageData = useSelector(state => state.pageData);
  const dispatch = useDispatch();
  const [sywPointsInDoller, setSywPointsInDoller] = useState("");
  const [backPageFlag, setBackPageFlag] = useState(false);
  const [limit, setLimit] = useState(Math.pow(10, process.env.REACT_APP_POINTS_DIGITS_ALLOWED));
	const [GTMHit, setGTMHit] = useState(true);

  // const obj1 = new Intl.NumberFormat('en-US');
  const [val, setVal] = useState("");
  const applyPointsHandller = () => {

    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.refid = sessionStorage.getItem("pywrefid");
    const requestOptions = {
      headers
    };
    var amount = document.querySelector(".points-input").value;
    amount = amount.indexOf("$") > -1 ? amount.substring(1, amount.length) : amount;
    dispatch(fetchApplyPoints(amount, requestOptions));
  }
  const backToPreviousPage = () => {
    setBackPageFlag(true);
  }
  // var points = sywPointsInDoller<=0?pageData?.sywPoints?.redeemPoints:sywPointsInDoller*1000;

  // points = obj1.format(points);
  // if(points === "NaN") points = "0";


  useEffect(() => {
    const userJson = JSON.stringify(user);
    const userNew = userJson.split(process.env.REACT_APP_CLAIMS_URL).join("");
    const user_json = JSON.parse(userNew);
    setUserInfo(user_json);
    const refid = sessionStorage.getItem("pywrefid");
    const items = [];
    let requestBody = { items };
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.refid = sessionStorage.getItem("pywrefid");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");
    headers.pywstate = PYWPOINTS + "~~" + sessionStorage.getItem("at0pyw") + duui.DeviceUUID().get();
    const requestHeaders = {
      headers
    };
    dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
  }, []);

  useEffect(() => {
		if (!pageData.pageLoading && pageData.isValid && GTMHit && process.env.REACT_APP_GTM_ANALYTICS===TRUE && !(pageData.pointApplySuccesfully || backPageFlag)) {
      GTMAnalytics("page", "checkout", {
        ...pageData,
        checkoutStep: 4,
        merchant_transaction_id: pageData?.txnId,
        ...pageData.responseData,
      });
			setGTMHit(false);
		}
	},[pageData])


  if (pageData.pageError) {
    return <PywError pageData={pageData} />
  } else if (pageData.pageLoading) {
    return (loading())
  } else if (pageData.isValid) {
    if (pageData.pointApplySuccesfully || backPageFlag) {
      return <div>{props.history.goBack()}</div>;
    }
    if (val.length === 0)
      setVal(pageData?.sywPoints?.redeemPointsInDollar);
    return (
      <main className="paymentoptions ff-open-sans flex-container">
        {headerPyw()}
        <section>
          {/* <PywTealiumAnalytics response={pageData} tealiumEvent="checkout" /> */}
				{process.env.REACT_APP_TEALIUM_ANALYTICS === "true" && <PywTealiumAnalytics response={pageData} tealiumEvent="checkout" />}

          {/* <AnalyticsWrapper
					 
					triggerType="page"
					event="checkout"
					data={{
            ...pageData,
            checkoutStep: 4
    }}
				/> */}
          <PywError pageData={pageData} />
          {headerUserWithPoints(pageData, userInfo)}
          <div className="container-wrapper-without-padding member-center-card">
            <div className="title-grey">
              <h4 className="mt-32 sm-mb-0 center fs-16 fw-400 lh-24">
                You have{" "}
                <span className="semi-light-blue">{pageData?.sywPoints?.currentPointsBalance}</span>
                {" "}available Shop Your Way points.
                <br />
                Apply them to your order!
              </h4>
              <div>
                <div className="input-box points-input-box">

                  <input
                    className="points-input mt-15 mb-3 center"
                    type="text"
                    defaultValue={"$" + val}
                    step={0}
                    onBlur={e => {
                      if (!(e.target.value.indexOf("$") > -1)) {
                        e.target.value = "$" + e.target.value
                        setVal(e.target.value);
                      }
                      var amountVal = e.target.value.indexOf("$") > -1 ? e.target.value.substring(1, e.target.value.length) : e.target.value

                      if (amountVal >= -1 || amountVal <= limit) {
                        amountVal = amountVal * 100 / 100;
                        amountVal = amountVal + "";
                        if (
                          amountVal.indexOf(".") > -1 &&
                          amountVal.split(".")[1].length === 1
                        ) {
                          setVal(amountVal + "0");
                          e.target.value = "$" + amountVal + "0"
                        }
                        else if (
                          amountVal.indexOf(".") === -1 &&
                          amountVal.length >= 1
                        ) {
                          setVal(amountVal + ".00");
                          e.target.value = "$" + amountVal + ".00";
                        }
                        else if (
                          amountVal.indexOf(".") > -1 &&
                          amountVal.split(".")[1].length > 2
                        ) {
                          setVal("0.00");
                          e.target.value = "$0.00"
                        }else{
                          setVal(amountVal);
                          e.target.value = "$" + amountVal 
                        }
                      }
                    }}
                    onChange={({ target }) => {
                      var amountVal = target.value.indexOf("$") > -1 ? target.value.substring(1, target.value.length) : target.value
                      amountVal = amountVal.replace(/[^0-9.]+/g, "");
                      if (amountVal.indexOf(".") > -1 && amountVal.indexOf(".", amountVal.indexOf(".") + 1) > -1) {
                        setVal(val)
                        target.value = "$" + val
                      } else {
                        if (
                          amountVal <= -0.01 ||
                          amountVal >= limit ||
                          (amountVal.indexOf(".") > -1 && amountVal.split(".")[1].length > 2)
                        ) {setVal(val)
                          target.value = "$"+val;
                          if (
                            amountVal.indexOf(".") > -1 &&
                            amountVal.split(".")[1].length > 2
                          ) {
                            target.value ="$"+val
                            setVal(val)
                          }
                        } else {
                          setVal(amountVal)
                          setSywPointsInDoller(amountVal)
                          target.value = "$"+amountVal

                        }
                      }
                    }}
                    onKeyPress={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </div>
              </div>
              <h4 className="center title-message-grey mb-16 fs-16 fw-400 lh-24 points-input-box">
                Enter the dollar amount of your purchase that you'd like to cover with Shop Your Way points.
              </h4>
              <p className="center title-message-grey points-grey"><small className="fw-400 fs-12 lh-16">This will use</small></p>
              <p className="center flex">
                <img src={images.sywpointsorange} alt="" />
                <p className="ml-8 orange fs-12 fw-400 lh-16">-{(new Intl.NumberFormat('en-US').format(sywPointsInDoller <= 0 ? parseInt(pageData?.sywPoints?.redeemPoints.replace(',', '')) : sywPointsInDoller * 1000))}</p>
              </p>
            </div>
          </div>
        </section>
        <footer className="container-wrapper-without-padding member-center-card">
          <button className="btn btn-primary mb-16"
            onClick={applyPointsHandller}>

            <b className="fw-700 fs-16 lh-20">Apply Points</b></button>
          <button className="btn btn-secondary"
            onClick={backToPreviousPage}
          ><b className="fw-700 fs-16 lh-20">Cancel</b></button>
        </footer>
      </main>
    )
  } else { return (<div />) }
}
export default PywApplyPoints;
