import { combineReducers } from 'redux'
import pywMemberDetailsReducer from './pywMemberDetails/pywMemberDetailsReducer'
import pywLearnMoreMemberDetailsReducer from './pywLearnMoreDetails/pywLearnMoreDetailsReducer'
import pywWebSocketReducer from './pywWebSocketDetails/pywWebSocketReducer'
const rootReducer = combineReducers({
  pageData: pywMemberDetailsReducer,
  LMPageData: pywLearnMoreMemberDetailsReducer,
  wsPageData: pywWebSocketReducer,
  })
export default rootReducer
