import React, {Component} from 'react'
import lock from '../assets/images/Lock.svg';
import sywLogo from '../assets/images/SYW_logo_black.svg';
import {images} from '../pywImagesComponent/PywImages';
import {openPopup} from "../pywUtilComponent/PywCitiHub.js";
import T1_StaticParts_Template from "../pywInitialTemplateComponent/T1_StaticParts_Template";
import T1_Offer_Template from "../pywInitialTemplateComponent/T1_Offer_Template";
import T2_StaticParts_Template from "../pywInitialTemplateComponent/T2_StaticParts_Template";
import T3_StaticParts_Template from "../pywInitialTemplateComponent/T3_StaticParts_Template";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import axios from "axios";
class PywInitialDynamic extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageData: '',
    };
  }

  componentDidMount() {  
     var element = document.querySelector(".initial");
     element.style.backgroundColor = this.props.pageData.CMP_PayYourWay.background;    
  }
  render() {          
    if (this.props.pageData.CMP_PayYourWay.template==="T1") {
      if (this.props.pageData.CMP_PayYourWay.offers !== undefined) {
        return (<T1_Offer_Template pageData={this.props.pageData}/>)
      }else {
        return (<T1_StaticParts_Template pageData={this.props.pageData}/>)
      }
    }else if(this.props.pageData.CMP_PayYourWay.template==="T2") {
      return (<T2_StaticParts_Template pageData={this.props.pageData}/>)
    }else if(this.props.pageData.CMP_PayYourWay.template==="T3") {
      return (<T3_StaticParts_Template pageData={this.props.pageData}/>)
    }
    else return <div/>
  }
}
export default PywInitialDynamic;
