import React, {Component}from "react";
import {template} from "./Templates";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";

class T1_Disable_StaticParts_Template extends Component {

    componentDidMount() {
        var element = document.querySelector(".initial");
        element.style.cssText += this.props.pageData.CMP_PayYourWay.templateCss;
    }

    render() {
        return (
            <div>
                {/* <PywTealiumAnalytics response={this.props.pageData} tealiumEvent="initial" /> */}
                <AnalyticsWrapper
					 
					triggerType="page"
					event="initial"
					data={this.props.pageData}
				/>
                    {this.props.pageData.CMP_PayYourWay.staticParts.map(parts => {
                        return (template(parts,true));
                    })}
            </div>
        );
    }
}
export default T1_Disable_StaticParts_Template;
