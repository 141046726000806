import {openPopup} from "../pywUtilComponent/PywCitiHub.js";
import { handleModalPopup } from "../pywUtilComponent/PywUtil.js";

export function template(parts, disableBtn) {

    if (parts.type === "image")
        return <img src={parts.url} alt=""/>
    else if (parts.type === "text&Image")
        return <div style={{marginBottom:parts.marginBottom}}><img src={parts.url} alt="" /><span dangerouslySetInnerHTML={{ __html: parts.content }}/></div>
    else if (parts.type === "text")
        return <span dangerouslySetInnerHTML={{ __html: parts.content }}/>
    else if (parts.type === "link")
        return <a onClick={e => { openPopup(parts.url) }} id="openModal"><span dangerouslySetInnerHTML={{ __html: parts.content }}/> </a>
    else if (parts.type === "link-modal")
        return <a id="openModal" onClick={e => { handleModalPopup(e, parts.url) }} ><span dangerouslySetInnerHTML={{ __html: parts.content }}/> </a>
    else if (parts.type === "button")
        return <button className= {disableBtn? "btninitial btninitial-disabled" : "btninitial btn-primary"}
            style={{borderRadius: parts.cornerRadius, fontSize:parts.fontSize ,backgroundColor: disableBtn ? parts.disabledBackground : ''}} 
            onClick={() => parts.Fn_PostMessage ? window.parent.postMessage('WINDOW_OPEN', '*'): "" }
            dangerouslySetInnerHTML={{ __html: parts.content }}></button>
    else if (parts.type === "styled-image")
        return <span dangerouslySetInnerHTML={{ __html: parts.content }}/>
}
