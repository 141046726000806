import React, { useEffect,useState }from "react";
import { images } from "../pywImagesComponent/PywImages";
import '../assets/styles/css/main.css';
import '../assets/styles/css/spinner.css';
import { hideBackdrop, hideTermShowCitiBackdrop,hideHSDeclineBackdrop } from "../pywUtilComponent/PywUtil.js";
const PywModalHSDeclineConfimation = ({setAnalEvent,isECM}) => {
  useEffect(() => { 
  }, []);

  const setEventAlert = (e) => {
      if (isECM == 'Y') {
        setAnalEvent("checkout_decline_ecm");
      } else if (isECM == 'N') {
        setAnalEvent("checkout_decline_nonecm");
      }
      hideHSDeclineBackdrop(e);
      alert('Offer Declined!');
    };

  return (
    <div id="declinebackdrop" class="declinebackdrop">
      <div class="ff-open-sans modal">
         <section class="modal-body">
            <h3 class=" mb-24 mt-24">Are you sure you want to close?</h3>
            <p class="mb-24">
              If customer chose to pay with Shop Your Way, then closing this page before payment confirmation will cancel this transaction.
              <br/>
              <br/>
            </p>
            <div class="btn-align mb-16">
              <button class="btn btn-secondary mr-8" id="payButton" onClick={e => { hideHSDeclineBackdrop(e) } }>Cancel</button>
              <button class="btn btn-primary ml-8" id="payButton"  onClick={e => { setEventAlert(e) }}>Ok</button>
            </div>
          </section>
      </div>
    </div>
  )
}
export default PywModalHSDeclineConfimation;