import * as rdd from "react-device-detect";
import cookies from "react-cookies";

function check5321Transacton(data) {
	var is5321TransactonResp = "No";
	if (
		data !== undefined &&
		data !== null &&
		data.tenders !== undefined &&
		data.tenders.length >= 1
	) {
		data.tenders.map((tender) => {
			if ("CC_DC" === tender.tenderType) {
				if (
					parseFloat(tender.amountPayable) !== 0 &&
					tender.creditCartTypeCode !== undefined &&
					tender.creditCartTypeCode === "I"
				) {
					is5321TransactonResp = "Yes";
				}
			}
		});
	}
	return is5321TransactonResp;
}
function is5321CardHolder(data) {
	var is5321CardHolder = "No";
	if (data !== undefined && data?.tenders instanceof Array) {
		data.tenders.map((tender) => {
			if ("CC_DC" === tender.tenderType) {
				if (
					tender.savedCards !== undefined &&
					tender.savedCards instanceof Array &&
					tender.savedCards.length > 0
				) {
					tender.savedCards.some((card) =>
						card.brand === "SYW" ? (is5321CardHolder = "Yes") : "No"
					);
				}
			}
		});
	}
	return is5321CardHolder;
}

function getCheckoutData(data) {
	var items = data?.items !== undefined ? data?.items : "";
	var itemIds = [];
	var itemNames = [];
	var itemPrices = [];
	var itemQty = [];
	var position = [];
	var is5321Transacton = check5321Transacton(data);
	for (const key in items) {
		position.push(Number(key) + 1);
		for (let item in items[key]) {
			if (item === "itemId") {
				itemIds.push(items[key][item]);
			}
			if (item === "itemName") {
				itemNames.push(items[key][item]);
			}
			if (item === "salePrice") {
				itemPrices.push(items[key][item]);
			}
			if (item === "quantity") {
				itemQty.push(items[key][item]);
			}
		}
	}
	return {
		item_id: itemIds,
		item_name: itemNames,
		item_brand: [],
		item_variant: [],
		item_category: [],
		price: itemPrices,
		quantity: itemQty,
		product_unit_price: itemPrices,
		product_position: position,
		CardHolder5321: is5321Transacton,
		checkout_step: data?.checkoutStep ? data?.checkoutStep : "",
	};
}
function getPaymentMethod(data) {
	var points = false;
	var cash = false;
	if (
		data !== undefined &&
		data !== null &&
		data.tenders !== undefined &&
		data.tenders.length >= 1
	) {
		data.tenders.map((tender) => {
			if (
				"SYW_POINTS" === tender.tenderType &&
				tender.redeemPoints !== undefined &&
				parseFloat(tender.redeemPoints) > 0
			) {
				points = true;
			} else if (
				"CC_DC" === tender.tenderType &&
				tender.amountPayable !== undefined &&
				parseFloat(tender.amountPayable) > 0
			) {
				cash = true;
			}
		});
	}

	var paymentMethod = "";
	if (cash && points) {
		paymentMethod = "Mix Tender";
	} else if (cash && !points) {
		paymentMethod = "cash";
	} else if (points && !cash) {
		paymentMethod = "points";
	}
	return paymentMethod;
}

function getOrderData(data) {
	let orderId,
		order_currency,
		order_zip,
		order_total,
		order_points,
		payment_method;

	if (data !== undefined) {
		let response = data;
		orderId = response.omsId;
		order_total =
			response.summary !== undefined ? response.summary.priceSubTotal : "";
		order_currency = "USD";
		order_zip = "";
		if (response.ffm !== undefined && response.ffm !== "") {
			response.ffm.map((ffm_address) => {
				if (ffm_address.type !== undefined && ffm_address.type === "SHIP") {
					var shipAddress = ffm_address.address;
					if (shipAddress !== undefined) {
						order_zip = shipAddress.zipCode;
					}
				}
			});
		}

		order_points =
			response.summary !== undefined ? response.summary.redeemPoints : "";
		payment_method = getPaymentMethod(response);
	}
	return {
		order_id: orderId,
		value: order_total,
		currency: order_currency,
		order_zip: order_zip,
		order_points: order_points,
		payment_method: payment_method,
	};
}
export function GTMAnalyticsGenerateData(data, event) {
	let pywmerchant =
		cookies.load("pywmerchant") !== undefined
			? cookies.load("pywmerchant")
			: data?.merchantName;
	const perfData = window.performance.timing;
	const pageLoadTime = perfData.loadEventEnd - perfData.navigationStart;
	const pageLoadTimeSec = (pageLoadTime / 1000) % 60;
	const pagePerformance = pageLoadTimeSec;
	if (!rdd.isBrowser) {
		pywmerchant = pywmerchant + "_app";
	}
	const pageType = ("pywext_" + pywmerchant).toLowerCase();

	var items = data.items !== undefined ? data.items : "";
	var itemIds = [];
	var itemNames = [];
	var itemPrices = [];
	var itemQty = [];
	var position = [];
	var isTransacton5321 = check5321Transacton(data);
	for (const key in items) {
		position.push(Number(key) + 1);
		for (let item in items[key]) {
			if (item === "itemId") {
				itemIds.push(items[key][item]);
			}
			if (item === "itemName") {
				itemNames.push(items[key][item]);
			}
			if (item === "salePrice") {
				itemPrices.push(items[key][item]);
			}
			if (item === "quantity") {
				itemQty.push(items[key][item]);
			}
		}
	}

	let EVENT_DATA = {
		eventAction: "Checkout click",
		eventCategory: "PYW Checkout",
		eventLabel: "Checkout",
		eventName: "Checkout",
		eventValue: "Checkout View",
	};
	let GLOBAL_DATA = {
		authenticationType: "",
		checkoutCartId: data?.cartId,
		hashedEmail: data?.hashedEmail,
		intcmp: "",
		pageUrl: window.location.origin + window.location.pathname,
		pagename: getPageName(window.location.pathname),
		pagePerformance: pagePerformance,
		pagetype: pageType,
		screen_title: "Pay your way",
		sid: getSid(),
		siteid: getSiteId(),
		siteLanguage: navigator.language,
		userId: data?.memberNumber ? data?.memberNumber : "",
		errorMessage: "",
		merchant_transaction_id: data?.merchant_transaction_id
			? data?.merchant_transaction_id
			: data?.txnId
			? data?.txnId.trim()
			: "",
		usertype: "Registered",

		authenticationStatus: "Registered", //
	};
	let CHECKOUT_DATA = {
		item_id: [],
		item_name: [],
		item_brand: [], //
		item_variant: [], //
		item_category: [], //
		price: [],
		quantity: [],
		product_unit_price: [],
		product_position: "",
		checkout_step: "",
	};
	let GTM_DATA = {
		...GLOBAL_DATA,
	};

	switch (event) {
		case "checkout":
			GTM_DATA = {
				...GTM_DATA,
				...getCheckoutData(data),
				event: "page_view_pyw",
				CardHolder5321: is5321CardHolder(data),
			};
			break;

		//Confirm payment page
		case "purchase":
			GTM_DATA = {
				...GTM_DATA,
				eventCategory: "pyw_order",
				eventAction: "pyw_order_status_contactless",
				eventLabel: "payment_confirm",
				eventValue: "payment_confirmed",
				transaction_id: data?.transaction_id,
				...getCheckoutData(data?.summary),
				...getOrderData(data),
				event: "purchase",
				"5321Transaction": isTransacton5321,
			};
			break;
		case "place_order":
			GTM_DATA = {
				...GTM_DATA,
				eventCategory: "pyw_order",
				eventAction: "pyw_place_order_btn_click",
				eventLabel: "order_placed",
				eventValue: "order_placed_yet_to_confirmed",
				...getCheckoutData(data),
				...getOrderData(data),
				event: "place_order",
				"5321Transaction": isTransacton5321,
				pagetype: !rdd.isBrowser
					? ("pywext_" + data?.merchantName + "_app").toLowerCase()
					: ("pywext_" + data?.merchantName).toLowerCase(),
			};
			break;
		case "initial":
			GTM_DATA = {
				...GTM_DATA,
				eventValue: "loading_view_finished",
				eventAction: "pyw_load_sdk_status",
				eventCategory: "pyw_load",
				eventLabel: "loading_finished",
				event: "page_view_pyw_initial",
			};
			break;
		case "pyw_order_failed":
			GTM_DATA = {
				...GTM_DATA,
				transaction_id: data?.transaction_id,
				eventValue: "order_failed",
				eventAction: "pyw_order_status",
				eventCategory: "pyw_order",
				eventLabel: "order_failed",
				event: "page_view_pyw",
			};
			break;
		case "pyw_order_hold":
			GTM_DATA = {
				...GTM_DATA,
				eventValue: "pyw_order_onhold",
				eventAction: "pyw_order_status",
				eventCategory: "pyw_order_status",
				eventLabel: "pyw_order_onhold",
				event: "page_view_pyw",
				"5321Transaction": isTransacton5321,
			};
			break;

		case "Linkclick":
			break;
		case "contactless_offerload":
			GTM_DATA = {
				...GTM_DATA,
				eventValue: "offer_loaded",
				eventAction: "pyw_offer_status_contactless",
				eventCategory: "pyw_offer",
				eventLabel: "offer_load",
				event: "page_view_pyw",
			};
			break;
		case "pyw_citi_card_declined":
			GTM_DATA = {
				...GTM_DATA,
				eventValue: "citi_card_declined",
				eventAction: "pyw_citi_card_status",
				eventCategory: "pyw_citi_card",
				eventLabel: "citi_card_declined",
				event: "page_view_pyw",
			};
			break;
		case "checkout_decline_ecm":
			GTM_DATA = {
				...GTM_DATA,
				eventAction: "ecm-checkout-decline",
				eventCategory: "ecm-checkout-decline",
				eventLabel: "ecm-checkout-decline",
				eventValue: "ecm-checkout-decline",
				event: "page_view_pyw",
			};
			break;
		case "checkout_decline_nonecm":
			GTM_DATA = {
				...GTM_DATA,
				eventAction: "nonecm-checkout-decline",
				eventCategory: "nonecm-checkout-decline",
				eventLabel: "nonecm-checkout-decline",
				eventValue: "nonecm-checkout-decline",
				event: "page_view_pyw",
			};
			break;
		case "contactless_offerload_decline":
			GTM_DATA = {
				...GTM_DATA,
				eventAction: "nonecm-offer-decline",
				eventCategory: "nonecm-offer-decline",
				eventLabel: "nonecm-offer-decline",
				eventValue: "nonecm-offer-decline",
				event: "page_view_pyw",
			};
			break;
		case "confirm_payment":
			GTM_DATA = {
				...GTM_DATA,
				eventValue: "payment_confirmed",
				eventAction: "pyw_order_status_contactless",
				eventCategory: "pyw_order",
				eventLabel: "payment_confirm",
				event: "page_view_pyw",
			};
			break;
		case "cancel_payment":
			GTM_DATA = {
				...GTM_DATA,
				eventValue: "payment_cancel",
				eventAction: "pyw_order_status_contactless",
				eventCategory: "pyw_order",
				eventLabel: "payment_cancel",
				event: "page_view_pyw",
			};
			break;
		case "onhold_payment":
			GTM_DATA = {
				...GTM_DATA,
				eventValue: "payment_onhold",
				eventAction: "pyw_order_status_contactless",
				eventCategory: "pyw_order",
				eventLabel: "payment_onhold",
				event: "page_view_pyw",
			};

			break;
		default:
			break;
	}

	return GTM_DATA;
}

function getPageName(url_path_name) {
	let pageName = "";
	if (url_path_name !== undefined) {
		switch (url_path_name) {
			case "/pyw/home":
				pageName = "pyw_home";
				break;
			case "/pyw/member":
				pageName = "pyw_member";
				break;
			case "/pyw/points":
				pageName = "pyw_points";
				break;
			case "/pyw/summary":
				pageName = "pyw_summary";
				break;
			case "/pyw/custom/summary":
				pageName = "pyw_summary";
				break;
			case "/pyw/error":
				pageName = "pyw_error";
				break;
			case "/pyw/disInitial":
				pageName = "pyw_disinitial";
				break;
			case "/pywInitial":
				pageName = "pyw_initial";
				break;
			case "/shs":
				pageName = "pyw_contactless_offer";
				break;
			case "/shsdesc":
				pageName = "pyw_contactless_qrcode";
				break;
			case "/shsstatus":
				pageName = "pyw_payment_confirm";
				break;
			case "/shscanstatus":
				pageName = "pyw_payment_cancel";
				break;
			case "/shsonhold":
				pageName = "pyw_payment_onhold";
				break;
			case "/pyw/order/cl/confirmation":
				pageName = "pyw_orderconfirmation";
				break;
			case "/pyw/confirmation":
				pageName = "pyw_orderconfirmation";
				break;
			case "/pyw/declined":
				pageName = "pyw_declined";
				break;
			case "/pyw/guest/summary":
				pageName = "pyw_guest_summary";
				break;
			case "lm/home":
				pageName = "pyw_lm_home";
				break;
			case "lm/member":
				pageName = "pyw_lm_member";
				break;
			case "/pyw/lm/declined":
				pageName = "pyw_lm_declined";
				break;
			case "/pyw/lm/summary":
				pageName = "pyw_lm_summary";
				break;
			case "/pyw/lm/citi/return/summary":
				pageName = "pyw_lm_citi_return_summary";
				break;
			case "/pyw/lm/guest/citi/return/summary":
				pageName = "pyw_lm_guest_citi_return_summary";
				break;
			default:
				return "";
		}
	}
	return pageName;
}

function getSid() {
	var sid = "";
	var s_pers_cookies = cookies.load("s_pers");
	if (s_pers_cookies !== undefined) {
		var s_pers = s_pers_cookies.split(";");
		s_pers.map((s_pers_nodes) => {
			s_pers_nodes = s_pers_nodes.trim();
			var s_pers_nodes_slit = s_pers_nodes.split("=");
			if (s_pers_nodes_slit[0].replace(/ /g, "") === "campaign") {
				sid = s_pers_nodes_slit[1];
			}
		});
	}
	return sid;
}

function getSiteId() {
	var user_agent = navigator.userAgent.toLowerCase();
	var siteId = "Unspecified";
	if (user_agent.indexOf("android") !== -1) {
		siteId = "Android";
	} else if (user_agent.indexOf("iphone") !== -1) {
		siteId = "iPhone";
	} else if (user_agent.indexOf("mobile") !== -1) {
		siteId = "Mobile";
	} else if (
		user_agent.indexOf("windows") !== -1 ||
		user_agent.indexOf("mac") !== -1 ||
		user_agent.indexOf("linux") !== -1
	) {
		siteId = "Web";
	}
	return siteId;
}
