import React from "react";
import { images } from "../pywImagesComponent/PywImages";
import { headerPyw } from "../pywConstantComponent/PywDesignsConstant";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";
const Pyw_PaymentOnHold = (props) => {
	return (
		<main className="flex-container">
			{headerPyw()}
			<section class="container container-wrapper container-wrapper-without-padding p-05">
				{/* <PywTealiumAnalytics response={props.pageData} tealiumEvent="pyw_order_hold" /> */}
				<AnalyticsWrapper
					triggerType="page"
					event="pyw_order_hold"
					data={{
						...props.pageData?.responseData,
						merchant_transaction_id: props?.pageData?.responseData?.txnId,
					}}
				/>
				<br />
				<br />
				<br />
				<br />
				<div class="center">
					<img src={images.NoCreditCardBlue} alt="" />
				</div>
				<br />
				<br />
				<h2 class="center">Payment on hold</h2>
				<br />
				<h4 class="grey60 center">
					Payment will be processed once merchant
					<br />
					confirms the order otherwise authorized
					<br />
					amount will be reversed within 2 days
				</h4>
			</section>
			<br />
			<br />
			<br />
			<footer>
				<p class="powered">
					<img src={images.lock} alt="" /> Powered by Shop Your Way
				</p>
			</footer>
		</main>
	);
};
export default Pyw_PaymentOnHold;
