import React, {  useEffect } from "react";
import { images } from "../pywImagesComponent/PywImages";
import { useSelector} from "react-redux";
import cookie from "react-cookies";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";
const Pyw_Confirmed = (props) => {
  const pageData = useSelector(state => state.pageData);
  useEffect(() => {
    alert('Payment Successful!');
    cookie.remove("pywid");
    cookie.remove("pywrefid");
  }, []);

  return (
    <main className="main-container mt-24">
      <section class="container-pay">
        {/* <PywTealiumAnalytics response={pageData} tealiumEvent="confirm_payment" /> */}
        <AnalyticsWrapper
					 
					triggerType="page"
					event="confirm_payment"
					data={pageData}
				/>
        <div class="price-detail shadow mb-16" style={{ borderRadius: "6px" }}>
          <div class="sh-pay-confirm pt-16 pb-16">
            <img class="mb-16 mt-16" src={images.rightGreen} style={{ height: '43px', width: '45px' }}></img>
            <span class="ff-open-sans sh-status ml-8">Payment Confirmed!</span>
          </div>
        </div>
      </section>
      <div class="container-footer">
        <button class="btn sh-btn-primary " onClick={() => alert('Exit-Checkout!') }>
          <span className="gotham-font-style hs-shs-btn-yes-decline">Exit to checkout</span>
        </button><br/>
      </div>
    </main>
  )
}
export default Pyw_Confirmed;

