function check5321Transacton(data){
    var is5321TransactonResp = "N"      
    if(data!==undefined && data!==null && data.tenders!== undefined && data.tenders.length>=1){
      data.tenders.map((tender)=>{
        if("CC_DC" === tender.tenderType){
          if(parseFloat(tender.amountPayable) !== 0  &&  tender.creditCartTypeCode !== undefined && tender.creditCartTypeCode === "I"){
            is5321TransactonResp = "Y"
          }
        }
      })
    }
    return is5321TransactonResp;
  }
function  getPaymentMethod(data){
    var points = false;
    var cash = false;
    if(data!==undefined && data!==null && data.tenders!== undefined && data.tenders.length>=1){
      data.tenders.map((tender)=>{
         if(("SYW_POINTS" === tender.tenderType) && tender.redeemPoints !== undefined && parseFloat(tender.redeemPoints) > 0){
              points = true;
            }else if(("CC_DC" === tender.tenderType) && tender.amountPayable !== undefined  && parseFloat(tender.amountPayable) > 0){
              cash =true;
          }
      })
    }
  
  var paymentMethod = "";
    if(cash && points){
      paymentMethod = "Mix Tender";
    }else if (cash && !points){
      paymentMethod = "cash";
    }else if(points && !cash){
      paymentMethod = "points";
    }
    return paymentMethod;
  
  }

  export function utag_placeorder_var(response) {
     var placeorder_data = {
      'tealium_event': 'place_order'
    }
    var purcahse_event_details = {
      "eventCategory": 'ecommerce',
      "eventAction": 'place order click',
      "eventName": 'place_order',
      "event_name": 'place_order',
      "eventLabel": 'confirm_payment_button',
      "eventValue" : "confirm_payment_button"
    }
    placeorder_data = Object.assign(placeorder_data, purcahse_event_details)
    if(response !== undefined){
      var orderId = response.omsId;
      var order_total = response.summary!==undefined?response.summary.priceSubTotal:"";
      var order_currency = "USD";
      var order_zip = "";
      if(response.ffm!==undefined && response.ffm!==""){
        response.ffm.map( (ffm_address) => {
          if(ffm_address.type!==undefined && ffm_address.type==='SHIP'){
            var shipAddress = ffm_address.address;
            if(shipAddress!==undefined){
              order_zip= shipAddress.zipCode;
            }
          } 
        }
        );
      }

      var order_points = response.summary!==undefined?response.summary.redeemPoints:"";
      var payment_method = getPaymentMethod(response);
      var items = response.summary!==undefined?response.summary.items:"";
      var itemIds = [];
      var itemNames = [];
      var itemPrices = [];
      var itemQty = [];
      var position = [];
      var is5321Transacton = check5321Transacton(response);
      for (const key in items) {
        position.push(Number(key) + 1)
        for (let item in items[key]) {
          if (item === "itemId") {
            itemIds.push(items[key][item])
          }
          if (item === "itemName") {
            itemNames.push(items[key][item])
          }
          if (item === "salePrice") {
            itemPrices.push(items[key][item])
          }
          if (item === "quantity") {
            itemQty.push(items[key][item])
          }
        }
      }       
      var purchase_order_data = {
        "order_id": orderId,
        "value": order_total,
        "currency": order_currency,
        "order_zip": order_zip,
        "order_points": order_points,
        "payment_method": payment_method,
        "item_id": itemIds,
        "item_name": itemNames,
        "item_brand": [],
        "item_variant": [],
        "item_category": [],
        "price": itemPrices,
        "quantity": itemQty,
        "5321Transaction" : is5321Transacton
      }
    }
  
    placeorder_data = Object.assign(placeorder_data, purchase_order_data)
    return placeorder_data;
  
  
  }