import React, { useEffect, useState } from 'react'
import postscribe from "postscribe";
import Pyw_PaymentLinkExpire from"./Pyw_PaymentLinkExpire";
import Pyw_PaymentLinkIssue from"./Pyw_PaymentLinkIssue";
import Pyw_PaymentCompleted from"./Pyw_PaymentCompleted";
const PywInitialNoView = (props) => {

  useEffect(() => {
    const queryString = require("query-string");
    const values = queryString.parse(props.location.search);
    // const refId = values.refId;
    // const totalDue = values.totalDue;
    // const returnUrl = values.returnUrl;
    const merchant = values.merchant===undefined?"":values.merchant;
    const id = values.id;
    var pywscript = '<script type="text/javascript" src="' + process.env.REACT_APP_PYW_SCRIPT_URL + '"> </script>';
    postscribe("#pywinitial", pywscript);
    //var prepPayment = '<script type="text/javascript"> preparePayment("' + encodeURI(refId) + '","' + totalDue + '","' + returnUrl + '","' + merchant + '"); </script>';
    var prepPayment = '<script type="text/javascript"> processNoViewPayment("' + id + '","' + merchant + '"); </script>';
    postscribe("#pywinitial", prepPayment);

    //document.getElementById("payprogress").classList.add("show");

  }, [])

  return (
    <div id="pywinitial">
      
      <div id="pywpopup" class="backdrop">
        <div  class="flex center">
          <div class="modal">
            <section class="modal-body">
              <p class="mt-8 mb-8 flex center">
                Pay with Shop Your Way in progress...
              </p>
            </section>
          </div>
        </div>
      </div>

      <div id="pywexpirypay" class="errorpayerr">
        <Pyw_PaymentLinkExpire/>
      </div>
      <div id="pywcmptpay" class="errorcmpt">
        <Pyw_PaymentCompleted/>
      </div>
      <div id="pywinvalidpaylink" class="errorinvalidpaylink">
        <Pyw_PaymentLinkIssue/>
      </div>
    </div>
  );

}
export default PywInitialNoView;