import React, { useState, useEffect } from "react";
import { images } from '../../pywImagesComponent/PywImages';
// import { Redirect } from "react-router-dom";
import { parseDollarValuetoPoints, parseDollarValue } from "../../pywUtilComponent/PywUtil.js";
import { PYWCUSTOMSUMMARY, PYW_MEMBER, SUBSCRIPTION, TRUE } from "../../pywConstantComponent/PywConstant"
import { CUSTOMCC } from "../../pywConstantComponent/PywSummaryMessageConstant"
import cookie from "react-cookies";
import { useSelector, useDispatch } from "react-redux";
import { fetchPrepareDetails, fetchPlaceOrder, fetchPlaceOrderAndUpdateCard, addSubscription } from "../../redux";
import Payment from "payment";
import PywError from "../../pywErrorComponent/PywError";
import * as duui from 'device-uuid'
import { useAuth0 } from "@auth0/auth0-react";
import postscribe from "postscribe";
import { headerBars, headerPyw, headerUserWithPoints, validatePayment, loading } from "../../pywConstantComponent/PywDesignsConstant";
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatNumber
} from "../../pywUtilComponent/PywCardUtil";
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
import { headerBar } from "../../pywConstantComponent/PywGuestDesignConstant";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";
import { GTMAnalytics } from "../../pywAnalytics/pywGTMAnalytics/Pyw_GTM_Analytics";

const PywCustomSummaryComponent = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [userInfo, setUserInfo] = useState("");
    const pageData = useSelector(state => state.pageData);
    const dispatch = useDispatch();
    const [navigate, setNavigate] = useState(false);
    const [backPageFlag, setBackPageFlag] = useState(false);
    const [cardNumber, setCardNumber] = useState("");
    const [cardError, setCardError] = useState("");
    const [expiry, setExpiry] = useState("");
    const [expiryError, setExpiryError] = useState("");
    const [cvv, setCvv] = useState("");
    const [cvvError, setCvvError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");
    const [memberFlag, setMemberFlag] = useState(false);
    const placeOrderHandller = () => {
        const headers = {
            Accept: "Application/json",
            "Content-Type": "application/json",
            channel: "ONLINE",
            platform: "PYW"
        };
        headers.cartId = sessionStorage.getItem("pywcartId");
        headers.merchantClientId = sessionStorage.getItem("pywmerchant");
        headers.refid = sessionStorage.getItem("pywrefid");
        const requestOptions = {
            headers
        };

        if (!pageData.tenderTypes.includes("CC_DC") || (parseFloat(pageData.summary.pointsDollarValue) === parseFloat(pageData.summary.priceSubTotal) && (pageData.addlAttrs?.payType === undefined || !pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION)))) {
            dispatch(fetchPlaceOrder(requestOptions));
        } else if (parseFloat(pageData.summary.pointsDollarValue) !== parseFloat(pageData.summary.priceSubTotal) || (pageData.addlAttrs?.payType !== undefined && pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION))) {

            const requestBody = {
                payment: [
                    {
                        paymentType: "creditCard",
                        creditCard: {
                            cardNumber: cardNumber,
                            creditCardType: Payment.fns.cardType(cardNumber),
                            cardLogoName: Payment.fns.cardType(cardNumber),
                            expiryDate: expiry.replace("/", "20"),
                            cvvNumber: cvv,
                            cardHolderName: name,
                            streetAddress: "USA",
                            zipCode: zipcode,
                            countryCode: "US",
                            currency: "USD"
                        }
                    }
                ]
            };

            if (pageData.addlAttrs?.payType !== undefined && pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION)) {
                let itemId = "";
                { pageData.items.map(item => { itemId = item.itemId; }); }
                var requestSubscription = {
                    "subscriptions": [
                        {
                            "itemId": itemId,
                            "subscription": {
                                "id": pageData.addlAttrs?.payType.replace(SUBSCRIPTION, "")
                            }
                        }
                    ]
                }

                dispatch(addSubscription(requestOptions, requestBody, requestSubscription));
            } else {
                dispatch(fetchPlaceOrderAndUpdateCard(requestOptions, requestBody));
            }

            //dispatch(fetchPlaceOrderAndUpdateCard(requestOptions, requestBody));
        }
    }
    const handleInputChange = ({ target }) => {
        if (target.name === "number") {
            target.value = formatCreditCardNumber(target.value);
            setCardNumber(target.value);
            if (Payment.fns.validateCardNumber(target.value)) {
                setCardError("");
            } else {
                setCardError("Enter valid card");
            }
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
            setExpiry(target.value)
            if (!Payment.fns.validateCardExpiry(target.value)) {
                setExpiryError("Enter the valid expiry date")
            } else {
                setExpiryError("")
            }
        } else if (target.name === "cvv") {
            target.value = formatCVC(target.value, cardNumber);
            setCvv(target.value);
            if (cardNumber === "") {
                setCvvError("Enter the card number first");
            } else {
                if (!Payment.fns.validateCardCVC(target.value, Payment.fns.cardType(cardNumber))) {
                    setCvvError("Enter valid CVV");
                    if (!Payment.fns.validateCardNumber(cardNumber)) {
                        setCvvError("Enter valid CVV");
                    }
                } else if (Payment.fns.validateCardCVC(target.value, Payment.fns.cardType(cardNumber))) {
                    if (!Payment.fns.validateCardNumber(cardNumber)) {
                        setCvvError("");
                        setCardError("Enter valid card");
                    } else {
                        setCvvError("");
                    }
                }
            }
        } else if (target.name === "name") {
            setName(target.value)
            if (target.value !== "") {
                setNameError("")
            } else {
                setNameError("Enter valid name")
            }
        } else if (target.name === "zipcode") {
            target.value = formatNumber(target.value)
            setZipcode(target.value)
            if (target.value !== "" && target.value.length == 5) {
                setZipcodeError("")
            } else {
                setZipcodeError("Enter valid zipcode")
            }
        }
    }

    useEffect(() => {
        const userJson = JSON.stringify(user);
        const userNew = userJson.split(process.env.REACT_APP_CLAIMS_URL).join("");
        const user_json = JSON.parse(userNew);
        setUserInfo(user_json);
        const refid = sessionStorage.getItem("pywrefid");
        const items = [];
        let requestBody = { items };
        const headers = {
            Accept: "Application/json",
            "Content-Type": "application/json",
            channel: "ONLINE",
            platform: "PYW"
        };
        headers.cartId = sessionStorage.getItem("pywcartId");
        headers.refid = sessionStorage.getItem("pywrefid");
        headers.merchantClientId = sessionStorage.getItem("pywmerchant");

        headers.pywstate = PYWCUSTOMSUMMARY + "~~" + sessionStorage.getItem("at0pyw") + duui.DeviceUUID().get();
        const requestHeaders = {
            headers
        };
        // var rtocken = '<script type="text/javascript" src="https://r0.shopyourway.com/w/profile"> </script>';
        // postscribe("#rtockenpost", rtocken);
        dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));

    }, []);

    useEffect(() => {
		if (
			pageData.orderConfirmationResponseCode !== undefined &&
			pageData.orderConfirmationResponseCode !== "" &&
			process.env.REACT_APP_GTM_ANALYTICS === TRUE
		) {
			var cartid = sessionStorage.getItem("pywcartId");
			var refid = sessionStorage.getItem("pywrefid");
			var merchant = sessionStorage.getItem("pywmerchant");

			GTMAnalytics("page", "place_order", {
				...pageData,
				checkoutStep: 3,
				cartId: cartid,
				merchantName: merchant,
				merchant_transaction_id: pageData?.txnId,
				...pageData.responseData,
			});
    }
    

	}, [pageData]);

	useEffect(() => {
		if (pageData.isValid) {
			GTMAnalytics("page", "checkout", {
				...pageData,
				checkoutStep: 3,
			});
		}
    }, [pageData.isValid]);
    
    const redirectToMember = () => {
        setMemberFlag(true);
    }
    if (memberFlag) {
        return <Redirect to={PYW_MEMBER} />;
    }

    return (
        <main id="rtockenpost" className="ff-open-sans flex-container">
            {(() => {

                if (pageData.orderConfirmationResponseCode !== undefined && pageData.orderConfirmationResponseCode !== '') {
                    var cartid = sessionStorage.getItem("pywcartId");
                    var refid = sessionStorage.getItem("pywrefid");
                    var merchant = sessionStorage.getItem("pywmerchant");
                    sessionStorage.removeItem("pywcartId");
                    sessionStorage.removeItem("pywrefid");
                    return (<div>
                        {/* <PywTealiumAnalytics response={pageData} tealiumEvent="place_order" /> */}
                        {/* <AnalyticsWrapper
					 
					triggerType="page"
					event="place_order"
					data={{
                        ...pageData,
                        checkoutStep: 3,
                        cartId: cartid,
                        merchantName: merchant,
                        merchant_transaction_id: pageData?.txnId,
                        ...pageData.responseData
                        
                }}
				/> */}
                 	{process.env.REACT_APP_TEALIUM_ANALYTICS === TRUE && (
								<PywTealiumAnalytics
									response={pageData}
									tealiumEvent="place_order"
								/>
							)}

                        {loading()}
                        {(() => {
                            if (pageData.responseData.redirect !== undefined && pageData.responseData.redirect === '_self') {
                                if (pageData.confirmStatus === "interim-order") {
                                    setTimeout(() => {
                                        window.location.replace(pageData.responseData.returnUrl + "?pywid=" + cartid + "&pywrefid=" + refid + "&merchant=" + merchant  + "&pywmsg=Y");
                                    }, "2000")
                                } else {
                                    setTimeout(() => {
                                        window.location.replace(pageData.responseData.returnUrl + "?pywid=" + cartid + "&pywrefid=" + refid + "&merchant=" + merchant  + "&pywmsg=N");
                                    }, "2000")
                                }
                            } else {
                                setTimeout(() => {
                                    window.close();
                                }, "2000")
                            }
                        })()}
                    </div>)
                } else {
                    if (navigate) {
                        return <Redirect to="/pyw/points" push={true} />
                    } else if (pageData.pageError) {
                        return <PywError pageData={pageData} />
                    } else if (pageData.validating) {
                        return (validatePayment())
                    } else if (pageData.pageLoading) {
                        return (loading())
                    } else if (pageData.isValid) {
                        if (pageData?.ccDc?.savedCards !== undefined && pageData?.ccDc?.savedCards.length >= 1) {
                            return <Redirect to="/pyw/summary" push={true} />;
                        }
                        return (
                            <div>
                                {headerPyw()}
                                {/* <PywTealiumAnalytics response={pageData} tealiumEvent="checkout" /> */}
                                {/* <AnalyticsWrapper
					 
					triggerType="page"
					event="checkout"
					data={pageData}
				/> */}
                                {process.env.REACT_APP_TEALIUM_ANALYTICS === TRUE && (
								<PywTealiumAnalytics
									response={pageData}
									tealiumEvent="checkout"
								/>
							)}
                                <PywError pageData={pageData} pageName="customSummary" />
                                <section>
                                    {headerUserWithPoints(pageData, userInfo)}
                                    {headerBar(2, pageData, setNavigate)}
                                    {
                                         pageData?.tenderTypes?.includes("SYW_POINTS") || pageData?.tenderTypes?.includes("CC_DC") ? 
                                         <div className="container-wrapper-without-padding">
                                        <div>
                                            {CUSTOMCC(pageData)}
                                        </div>
                                        
                                            <div>

                                            {(() => {
                                                if (pageData?.tenderTypes?.includes("CC_DC") && ((pageData.summary.priceSubTotal > parseDollarValue(pageData?.sywPoints?.currentPointsBalance)) || (pageData.addlAttrs?.payType != undefined && pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION)))) {
                                                    return (
                                                        <div>
                                                            <div className="container-cc" >
                                                                <figure className="cc">
                                                                    <img src={images.visa} alt="" />
                                                                </figure>
                                                                <figure className="cc">
                                                                    <img src={images.masterCard} alt="" />
                                                                </figure>
                                                                <figure className="cc">
                                                                    <img src={images.discover} alt="" />
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })()}
                                            {(() => {
                                                if (pageData?.tenderTypes?.includes("CC_DC") && (pageData.summary.priceSubTotal > parseDollarValue(pageData?.sywPoints?.currentPointsBalance) || (pageData.addlAttrs?.payType !== undefined && pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION)))) {
                                                    return (
                                                        <form>
                                                            <div className="mb-12">
                                                                <label className="input-label ">
                                                                    <input name="number" onChange={handleInputChange} className="input-text input-text-custom" value={cardNumber} type="text" autocomplete="off" required />
                                                                    <span className="label-span title-message-grey fw-400 fs-14 lh-16">Card Number</span>
                                                                </label>
                                                                <small className="orange alert show" id="alert">{cardError}</small>
                                                            </div>
                                                            <div className="mb-12">
                                                                <div className="date-cvv">
                                                                    <label className="input-label ">
                                                                        <input name="expiry" type="text" value={expiry} onChange={handleInputChange} className="input-text half input-text-custom" autocomplete="off" required />
                                                                        <span className="label-span title-message-grey fw-400 fs-14 lh-16">mm/yy</span>
                                                                    </label>
                                                                    <label className="input-label ">
                                                                        <input name='cvv' value={cvv} type="text" onChange={handleInputChange} className="input-text half input-text-custom" autocomplete="off" required />
                                                                        <span className="label-span title-message-grey fw-400 fs-14 lh-16">cvv</span>
                                                                    </label>
                                                                </div>
                                                                <div className="date-cvv">
                                                                    <small className="orange alert show half mb-2 mt-0" id="alert">{expiryError}</small>
                                                                    <small className="orange alert show half mb-2 mt-0" id="alert">{cvvError}</small>
                                                                </div>
                                                            </div>
                                                            <label className="input-label ">
                                                                <input name="name" value={name} type="text" onChange={handleInputChange} className="input-text mb-12 input-text-custom" onKeyPress={e => {
                                                                    if (!/[a-zA-Z ]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                    e.key === "Enter" && e.preventDefault();
                                                                }} autocomplete="off" required />
                                                                <span className="label-span title-message-grey fw-400 fs-14 lh-16">Name</span>
                                                            </label>
                                                            <label className="input-label">
                                                                <input name="zipcode" type="text" value={zipcode} onKeyPress={e => {
                                                                    if (!/[0-9]/.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                    e.key === "Enter" && e.preventDefault();
                                                                }} onChange={handleInputChange} className="input-text  input-text-custom mb-12" maxLength={5}  autoComplete="off" required />
                                                                <span className="label-span title-message-grey fw-400 fs-14 ">Zip Code</span>
                                                                <small className="orange alert show" id="alert">{zipcodeError}</small>
                                                            </label>

                                                        </form>
                                                    );
                                                }
                                            })()}

                                            {(() => {
                                                if (pageData?.tenderTypes?.includes("SYW_POINTS") && (pageData.summary.priceSubTotal <= parseDollarValue(pageData?.sywPoints?.currentPointsBalance))) {
                                                    return (
                                                        <>
                                                            <div className="card points mb-16 you-need-message mt-20">
                                                                <p className="fs-14">You need<img src={images.Path} className="ml-8 mr-6 coin-logo-img" alt="" /><span className="semi-light-blue fs-12">{(new Intl.NumberFormat('en-US').format(parseDollarValuetoPoints(pageData.summary.priceSubTotal)))}</span>  points to complete this purchase.</p>
                                                            </div>
                                                            {(() => {
                                                                if (!(parseFloat(pageData.summary.paymentDue) !== 0 &&
                                                                    pageData.summary.priceSubTotal <= parseDollarValue(pageData?.sywPoints?.currentPointsBalance))) {
                                                                    return <div className="mb-points-message"></div>
                                                                }
                                                            })()}</>
                                                    );
                                                }
                                            })()}
                                            {(() => {
                                                if (pageData?.tenderTypes?.includes("CC_DC") && ((pageData.summary.earnPointSummary !== undefined && parseInt(pageData.summary.earnPointSummary.totalEarnPoints) > 0) && (pageData.summary.priceSubTotal > parseDollarValue(pageData?.sywPoints?.currentPointsBalance) || (pageData.addlAttrs?.payType !== undefined && pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION))))) {
                                                    return (<p className="dark-grey fs-14 earn-points-message fw-400 lh-20">Earn <span className="semi-light-blue">{(new Intl.NumberFormat('en-US').format(pageData.summary.earnPointSummary.totalEarnPoints))}</span> Shop Your Way points with this purchase!</p>);
                                                }
                                            })()}
                                            {(() => {
                                                if (pageData.summary.priceSubTotal < parseDollarValue(pageData?.sywPoints?.currentPointsBalance) || (pageData.addlAttrs?.payType === undefined || !pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION))) {
                                                    return <div className="mt-0"></div>
                                                }
                                            })()}
                                        </div>
                                        
                                        { !pageData?.tenderTypes?.includes("CC_DC") && 
                                            pageData.summary.priceSubTotal > parseDollarValue(pageData?.sywPoints?.currentPointsBalance) &&
                                            <div className="tender-body"></div> 
                                        }
                                    </div> : <div className="tender-body"></div>
                                    }
                                    
                                </section>
                                <footer className="container-wrapper-without-padding mb-62 pb-0 nocc-109-footer">
                                    {(() => {
                                        if (parseFloat(pageData.summary.tenderAmountDue) !== 0 &&
                                            pageData.summary.priceSubTotal <= parseDollarValue(pageData?.sywPoints?.currentPointsBalance)) {
                                            return (<h5 className="orange insufficent-points fs-14 mt-20 mb-73 lh-20">Insufficient points to make this purchase. Use your Shop Your Way Points to complete the purchase.</h5>);
                                        }
                                    })()}

                                    {(() => {
                                        if ((parseFloat(pageData.summary.tenderAmountDue) === 0 && (pageData.addlAttrs?.payType === undefined || !pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION))) || (name != "" && zipcode != "" && zipcode.length == 5) && (cardError == "" && cvvError == "" && expiryError == "" && zipcodeError == "")) {
                                            return (<button className="btn btn-primary h-52" id="payButton" onClick={placeOrderHandller} ><b className="fs-16">{pageData?.cartResponseData?.label?.complete_payment ? pageData?.cartResponseData?.label?.complete_payment : "Complete Payment"}</b></button>);
                                        } else {
                                            return (<button className="btn btn-primary h-52" id="payButton" disabled="disabled" ><b className="fs-16">{pageData?.cartResponseData?.label?.complete_payment ? pageData?.cartResponseData?.label?.complete_payment : "Complete Payment"}</b></button>);
                                        }
                                    })()}
                                    {/* if (pageData.summary.priceSubTotal > parseDollarValue(pageData?.sywPoints?.currentPointsBalance) || (pageData.addlAttrs?.payType!==undefined && pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION))) { */}
                                    {
                                        pageData?.cartResponseData.ccInfo?.map((info) => {
                                            if (( pageData?.tenderTypes?.includes("APPLYANDBUY") && info.provider === 'CITI' && info.appliedForCard !== "Y") && (pageData.summary.priceSubTotal > parseDollarValue(pageData?.sywPoints?.currentPointsBalance))) {

                                                return <button onClick={redirectToMember} className="btn btn-secondary h-52" style={{ marginTop: "0.5rem" }}><b className="title-grey fs-16">Back</b></button>

                                            }
                                        })
                                    }

                                </footer>
                            </div>
                        )
                    } else { return (<div />) }
                }
            }
            )()}
        </main>
    )
}
export default PywCustomSummaryComponent
