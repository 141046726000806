import React, {Component}from "react";
import {template} from "./Templates";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";

class T2_StaticParts_Template extends Component {

    componentDidMount() {
        var element = document.querySelector(".initial");
        element.style.cssText += this.props.pageData.CMP_PayYourWay.templateCss;
    }

    render() {
        return (
            <div>
                {/* <PywTealiumAnalytics response={this.props.pageData} tealiumEvent="initial" /> */}
                <AnalyticsWrapper
					 
					triggerType="page"
					event="initial"
					data={this.props.pageData}
				/>
                    {this.props.pageData.CMP_PayYourWay.staticParts.map(parts => {
                        if (parts.part === 1) {
                                        return (<div className='unif mb-6'> {template(parts)}</div>)
                        }else if (parts.part === 2) {
                                        return (<div className='unif h1'>{template(parts)}</div>)
                        }else if (parts.part === 3) {
                                        return (<div className='unif h2'>{template(parts)}</div>)
                        }
                    })}
            </div>
        );
    }
}
export default T2_StaticParts_Template;