import { images } from '../pywImagesComponent/PywImages';
import { handleClickErrorBanner, handleClickSuccessBanner } from '../pywUtilComponent/PywUtil';
import { EMAIL_FAILURE_MESSAGE, EMAIL_SUCCESS_MESSAGE, LOADING, SUCCESS, WSLOADING } from "./PywConstant";
//-------------Save Card scenarios-----------------------------
export function progressBar(val) {
    if (val === 1)
        return <div className="progressbar mb-40">
            <div className="progressbar-step-select semi-light-blue fs-14">
                1. Join
            </div>
            <div className="progressbar-step-disable dark-grey fs-14">
                2. Apply
            </div>
            <div className="progressbar-step-disable dark-grey fs-14">
                3. Pay
            </div>
        </div>;
    else if (val === 2)
        return <div className="progressbar mb-40">
            <div className="progressbar-step-disable-left">
                <div className="dot">
                    <span className="checkmark">
                        <div className="checkmark_stem"></div>
                        <div className="checkmark_kick"></div>
                    </span>
                </div>
            </div>
            <div className="progressbar-step-select semi-light-blue fs-14">
                2. Apply
            </div>
            <div className="progressbar-step-disable dark-grey fs-14">
                3. Pay
            </div>
        </div>;
    else if (val === 3)
        return <div className="progressbar mb-40">
            <div className="progressbar-step-disable-left">
                <div className="dot">
                    <span className="checkmark">
                        <div className="checkmark_stem"></div>
                        <div className="checkmark_kick"></div>
                    </span>
                </div>
            </div>
            <div className="progressbar-step-disable-left">
                <div className="dot">
                    <span className="checkmark">
                        <div className="checkmark_stem"></div>
                        <div className="checkmark_kick"></div>
                    </span>
                </div>
            </div>
            <div className="progressbar-step-select semi-light-blue fs-14">
                3. Pay
            </div>
        </div>;
}

//--------------Common Header----------------------------------
export function headerPyw() {
    return (
        <div>
            <header className="main-header">
                <h5 className="white headline">Pay with Shop Your Way</h5>
            </header>
        </div>
    )
}

export function headerMerchant(pageData) {
    return (
        <div className="merchant">
            <div className="merchant-image">
                {(() => {
                    if (pageData.cartResponseData.merchantLogoUrl) {
                        return (
                            <img src={pageData.cartResponseData.merchantLogoUrl} alt="Merchant Icon" className="avatar_round" />
                        )
                    }
                })()}
                <p className="title-grey word-break fs-16">{pageData.merchantName}</p>
            </div>
            <div className="merchant-amount">
                <img src={images.cart} alt="Cart Icon" className="card_icon" />
                <span className="title-grey fs-16">{pageData.summary.currency + pageData.summary.priceSubTotal}</span>

            </div>
        </div>
    )
}
export function headerMerchantLearnMoreHome(pageData) {
    return (
        <div className="merchant h-52">
            <div className="merchant-image">
                {(() => {
                    if (pageData.merchantDetailResponse.merchantDetails.merchantUrl) {
                        return (
                            <img src={pageData.merchantDetailResponse.merchantDetails.merchantUrl} alt="Merchant Icon" className="avatar_round" />
                        )
                    }
                })()}
                <p className="title-grey"><b className='fs-16 fw-400 lh-20'>{pageData.merchantDetailResponse.merchantDetails.merchantName}</b></p>
            </div>
        </div>
    )
}
export function headerMemberLearnMore(pageData) {
    return (
        <div className="merchant h-52">
            <div className="merchant-image">
                {(() => {
                    if (pageData.memberDetailsResponse.merchantDetails.merchantDetails.merchantUrl) {
                        return (
                            <img src={pageData.memberDetailsResponse.merchantDetails.merchantDetails.merchantUrl} alt="Merchant Icon" className="avatar_round" />
                        )
                    }
                })()}
                <p className="title-grey"><b className='fs-16 fw-400 lh-20'>{pageData.memberDetailsResponse.merchantDetails.merchantDetails.merchantName}</b></p>
            </div>
        </div>
    )
}
export function headerUserWithPoints(pageData, userInfo) {
    return (
        <div className="user">
            <div className="user-image">
                <img src={userInfo.picture} alt="" />
                {pageData.ffm.map(ffm => {
                    if ("EMAIL" === ffm.type) {
                        return (<p className='fs-16 black fw-700 lh-20'>{ffm.firstName + " " + ffm.lastName}</p>)
                    }
                })}
            </div>
            {
                pageData?.tenderTypes?.includes("SYW_POINTS") && (
                    <div className="counter-points">
                        <div className="coin-logo">
                            <img className="path-img" src={images.Path} alt="" />
                    </div>
                        <span className="semi-light-blue ml-8 fw-600">{pageData?.sywPoints?.currentPointsBalance ? pageData?.sywPoints?.currentPointsBalance : "0"}</span>
                    </div>
                )
            }
            
        </div>
    )
}

export function headerUserWithPointsLearnMore(pageData) {
    return (<div className="user">
        <div className="user-image">
            <img src={pageData.memberDetailsResponse.memberDetails.SYWImageUrl} alt="" />
            <p className='fs-16 black fw-400 lh-20'>{pageData.memberDetailsResponse.memberDetails.firstName+" "+pageData.memberDetailsResponse.memberDetails.lastName}</p>
        </div>
        <div className="counter-points">
            <div className="coin-logo">
                <img className="path-img" src={images.Path} alt="" />
            </div>
            <span className="semi-light-blue ml-8 fw-600 fs-14 lh-16">{new Intl.NumberFormat('en-US').format(pageData.memberDetailsResponse.memberDetails.currentPointsBalance)}</span>
        </div>
    </div>)
}

export function headerUserWithPointsLearnMoreGuest(pageData) {
    return (<div className="user">
        <div className="user-image">
            <img src={images.user} alt="" />
            <p className='fs-16 black fw-600 lh-20'>{pageData.memberDetailsResponse.memberDetails.emailId}</p>
        </div>
        <div className="counter-points">
            <div className="coin-logo">
                <img className="path-img" src={images.Path} alt="" />
            </div>
            <span className="semi-light-blue ml-8 fw-600 fs-14 lh-16">0</span>
        </div>
    </div>)
}
export function headerSywPoints(pageData, setNavigate) {
    return (
        <dl className="price-detail-item pt-12 pb-11">
            {(() => {
                if (parseFloat(pageData?.sywPoints?.currentPointsBalance) !== 0.00) {
                    return (
                        <dt className='fs-14 fw-400' style={{ color: "#262C2F" }}>Shop Your Way points.<a onClick={() => setNavigate(true)} className=" ml-4">Edit</a></dt>
                    );
                }
            })()}
            {(() => {
                if (parseFloat(pageData?.sywPoints?.currentPointsBalance) === 0.00) {
                    return (
                        <dt className='fw-400'>Shop Your Way points.<a className="ml-4 disabled title-message-grey fw-400">Edit</a></dt>
                    );
                }
            })()}
            <dd><span className="semi-light-blue fs-14 fw-400">-{pageData.summary.currency + pageData.summary.pointsDollarValue}</span></dd>
        </dl>
    )
}

export function headerOutStandingBalance(pageData) {
    return (
        <dl className="price-detail-item pt-12 pb-16">
            <dt><b className="title-grey fs-14 fw-700">Outstanding Balance: </b></dt>
            <dd><span className="orange-updated fs-14"><b>{pageData.summary.currency + pageData.summary.paymentDue}</b></span> </dd>
        </dl>
    )
}

export function headerBars(value, pageData, setNavigate) {
    if (value === 1) {
        return (
            <div className="price-detail mb-16 shadow">
                {headerMerchant(pageData)}
            </div>
        )
    }
    else if (value === 2) {
        return (
            <div className="price-detail shadow  mb-20">
                {headerMerchant(pageData)}
                {pageData?.tenderTypes?.includes("SYW_POINTS") && headerSywPoints(pageData, setNavigate)}
                {headerOutStandingBalance(pageData)}
            </div>
        )
    } else if (value === 3) {
        return (
            <div className="price-detail shadow  mb-20">
                {headerMerchant(pageData)}
                {pageData?.tenderTypes?.includes("SYW_POINTS") && headerSywPoints(pageData, setNavigate)}
                {(() => {
                    if(pageData.summary.totalDiscountAmount !== "0.00" && pageData.summary.totalDiscountAmount !== undefined){
                        return savingHeader(pageData)
                    }
                })() }
                {headerOutStandingBalance(pageData)}

            </div>
        )
    }
}
export function headerBarsLearnMore(value, pageData, setNavigate) {
    if (value === 1) {
        return (
            <div className="price-detail mb-16 shadow">
                {headerMerchantLearnMoreHome(pageData)}
            </div>
        )
    }
    else if (value === 2) {
        return (
            <div className="price-detail mb-12 shadow">
                {headerMemberLearnMore(pageData)}
            </div>
        )
    }
}

export function brandMessageHome(lMorePageData) {
    if (lMorePageData.merchantDetailResponse && lMorePageData.merchantDetailResponse.merchantDetails && lMorePageData.merchantDetailResponse.merchantDetails.merchantMsg !== undefined &&
        Object.keys(lMorePageData.merchantDetailResponse.merchantDetails.merchantMsg).length !== 0
        && lMorePageData.merchantDetailResponse.merchantDetails.merchantMsg.HOME_SCREEN_MSG) {
        return <div className="brand-message"> <b>{lMorePageData.merchantDetailResponse.merchantDetails.merchantMsg.HOME_SCREEN_MSG}</b></div>
    }
}
export function brandMessageMember(lMorePageData) {
    if (lMorePageData.merchantDetailResponse && lMorePageData.merchantDetailResponse.merchantDetails && lMorePageData.merchantDetailResponse.merchantDetails.merchantMsg !== undefined &&
        Object.keys(lMorePageData.merchantDetailResponse.merchantDetails.merchantMsg).length !== 0
        && lMorePageData.merchantDetailResponse.merchantDetails.merchantMsg.MEMBER_SCREEN_MSG) {
        return <p className="mb-20 mt-20 title-grey member-brand-msg fw-400">
            {lMorePageData.merchantDetailResponse.merchantDetails.merchantMsg.MEMBER_SCREEN_MSG}</p>

    }

}
export function validatePayment() {
    return <div > {headerPyw()}
    <div className='validation-loader'>
        <section className="container container-wrapper loading-card">
            <img src={images.loadingCard} alt="Loading" />
            <p>Validating payment...</p>
        </section>

        <footer className="footer-container-wrapper powered">
            <p className="powered"><img src={images.lock} alt="" /> Powered by Shop Your Way</p>
        </footer>
    </div>
    </div>
}
export function loading() {
    return <section className="container loading-card mt-32 h-166">
        <div className="loader" />
        <p className="mt-16">{LOADING}</p>
    </section>
}
export function miniloading() {
    return <div className='miniloading-card'><div className="mini-loader"></div>Please wait</div>
}

export function savingHeader(pageData) {
    return (
        <dl className="price-detail-item ws_nwr">
            {
                pageData?.cartResponseData?.label?.promotion_label ? 
                <dt><b className="title-grey white-spc fs-14">{pageData?.cartResponseData?.label?.promotion_label}</b></dt> :
                <dt><b className="title-grey white-spc fs-14">Shop Your Way Mastercard<sup className="sup-without-fonts">�</sup> savings</b></dt>
            }
            
            <dd><span className="semi-light-blue fs-14" ><b>-{pageData.summary.currency + (pageData.summary.totalDiscountAmount === undefined ? "0.00" : pageData.summary.totalDiscountAmount)}</b></span></dd>
        </dl>

    )
}
export function webSocketLoading() {
    return <section className="container loading-card mt-32 h-166">
        <div className="loader" />
        <p className="mt-16">{WSLOADING}</p>
    </section>
}
export function PywEmailFeedbackComponent(sendEmailResponse){
    const STATUS=sendEmailResponse?.status;
    const MESSAGE = STATUS === SUCCESS ? EMAIL_SUCCESS_MESSAGE : EMAIL_FAILURE_MESSAGE ;
    return (
    <div>
       <div class={`${STATUS}-banner`}>
          <p>{MESSAGE}</p>
          <button
             id="hideBanner"
             onClick={(e) => {
                if(STATUS==="success"){
                    handleClickSuccessBanner(e);
                }else{
                    handleClickErrorBanner(e)
                }
          }}
          >
          <img src={images.closewhite} alt="" />
          </button>
       </div>
    </div>
    )   
}
