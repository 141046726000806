import React, { useEffect, useState } from "react";
import { images } from "../../pywImagesComponent/PywImages";
import postscribe from "postscribe";
import { closeWindow, showInfoBackdrop } from "../../pywUtilComponent/PywUtil";
import {
  declineMsg,
  headerBar,
  headerPyw,
  headerUserWithNoPointsGuest,
  headerUserWithPoints,
  headerUserWithPointsGuest,
  progressBar,
} from "../../pywConstantComponent/PywGuestDesignConstant";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { disableButtonClick, fetchPrepareDetails } from "../../redux";
import { loading } from "../../pywConstantComponent/PywDesignsConstant";
import PywError from "../../pywErrorComponent/PywError";
import PywInfomodelbackdrop from "../../pywModalComponent/PywInfomodelbackdrop";
// import { Redirect } from "react-router-dom";
import { PYWDECLINED, PYW_CUSTOM_SUMMARY, PYW_GUEST_SUMMARY_PATH } from "../../pywConstantComponent/PywConstant";
import * as duui from "device-uuid";
import { Redirect } from "../../pywUtilComponent/PywRedirect";
import AnalyticsWrapper from "../../pywAnalytics/AnalyticsWrapper";

const PywGuestCardDeclined = () => {
  const pageData = useSelector(state => state.pageData);
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const [saveCcardId, setSaveCcardId] = useState("");
  const { user, isAuthenticated } = useAuth0();
  const [userInfo, setUserInfo] = useState("");
  const { loginWithRedirect } = useAuth0();
  const [emailAddress, setEmailAddress] = useState("");
  useEffect(() => {
    const refid = sessionStorage.getItem("pywrefid");
    const items = [];
    let requestBody = { items };
    const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW"
    };
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.refid = sessionStorage.getItem("pywrefid");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");

    headers.pywstate = PYWDECLINED + "~~" + sessionStorage.getItem("at0pyw") + duui.DeviceUUID().get();
    const requestHeaders = {
      headers
    };
    // var rtocken = '<script type="text/javascript" src="https://r0.shopyourway.com/w/profile"> </script>';
    // postscribe("#rtockenpost", rtocken);
    dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
  }, [isAuthenticated]);
  return (
    <main id="rtockenpost" className="ff-open-sans flex-container">
      {(() => {
        if (isAuthenticated) {
          return <Redirect to={PYW_CUSTOM_SUMMARY} push={true} />;
        }
        if (pageData.pageError) {
          return <PywError pageData={pageData} />;
        } else if (pageData.pageLoading || pageData.isDisableButtonClick) {
          return (
            loading())
        } else if (pageData.isValid) {
          pageData.ffm.map(ffm => {
            if ("EMAIL" === ffm.type && emailAddress == "") {
              setEmailAddress(ffm.emailId)
            }
          })
          if (pageData.ccDc.savedCards !== undefined && pageData.ccDc.savedCards.length >= 1) {
            return <Redirect to={PYW_GUEST_SUMMARY_PATH} push={true} />;
          }
          return (<section>
           <AnalyticsWrapper
					 
					triggerType="page"
          event="pyw_citi_card_declined"
					data={pageData}
				/>
            {headerPyw()}
            {headerBar(4, pageData)}

            <div className="container-wrapper-without-padding declined-center pl-0 pr-0">
              {declineMsg(pageData)}
              <div className="middle-content-declined">
                <div className="declined-inner-parent">
                  <div className="declined-inner-child">
                    <div className="logo-img-declined">
                      <img src={images.SYWLogo}></img>
                    </div>
                    <div className="declined-col-2">
                      <p className="grey-scale fw-700 fs-16 lh-20 mb-8">
                      Become a Shop Your Way member and earn instantly!
                      </p>
                      <p className="fw-400 fs-14 lh-20">
                        Earn <span className="declined-blue fw-700">1% </span>
                        Shop Your Way points on this purchase by using a credit card and paying as a member.
                      </p>
                      <div className="declined-cc">
                        <figure>
                          <img src={images.visa} alt="" />
                        </figure>
                        <figure>
                          <img src={images.masterCard} alt="" />
                        </figure>
                        <figure>
                          <img src={images.discover} alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className=" frame-icon-size mr-16">
                    <a onClick={e => { showInfoBackdrop(e) }} className="frame-info-link " id="infoopenModal"><img src={images.info} alt="" /></a>
                  </div>
                </div>
                <button className="btn btn-primary member-footer mt-16" id="payButton" onClick={e => {
                dispatch(disableButtonClick()); loginWithRedirect({ screen_hint: "signup"})
              }}>
                <b className="fw-700 fs-16 lh-20">Join Shop Your Way or Login to Pay</b>
              </button>
              </div>
            </div>
            <footer className="container-wrapper-without-padding member-center-card btn-summary-continue mb-30 pb-0">
              {
                window.opener && !window.opener.closed? (
                  <button className="btn btn-secondary h-52" id="payButton" onClick={e => {
                    // dispatch(disableButtonClick())
                    closeWindow()
                  }}>
                    <b className="title-grey fs-16 lh-20 fw-700">Return to vendor</b>
                  </button>
                ) : <></>
              }

              <PywInfomodelbackdrop btnflag="citibtn" />
            </footer>
          </section>
          )
        }


      })()}

    </main>
  );
};

export default PywGuestCardDeclined;
