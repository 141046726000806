import React, { useState, useEffect } from "react";
import '../assets/styles/css/spinner.css';
import { images } from "../pywImagesComponent/PywImages";
import { useSelector, useDispatch } from "react-redux";
import cookie from "react-cookies";
import { orderConfirmationDetails } from "../redux";
import PywError from"../pywErrorComponent/PywError";
import { handleChange, showHSDeclineBackdrop } from "../pywUtilComponent/PywUtil.js";
import { LOADING} from "../pywConstantComponent/PywConstant"
import { QRCodeSVG } from 'qrcode.react';
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import PywModalHSDeclineConfimation from "../pywModalComponent/PywModalHSDeclineConfimation"
import axios from "axios";
import { fetchWebSocketDetails } from "../redux/pywWebSocketDetails/pywWebSocketActions";
import { loading } from "../pywConstantComponent/PywDesignsConstant";
import { WS_PARAMS } from "../pywConstantComponent/pywWebSocketConstant";
import { Redirect } from "../pywUtilComponent/PywRedirect";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";
const Pyw_Description = (props) => {
  const dispatch = useDispatch();
  const pageData = useSelector(state => state.pageData);
  const [pmtId, setPmtId] = useState('');
  const [merchant, setMerchant] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [QRLink, setQRLink] = useState('');
  const [email, setEmail] = useState('');
  const [durationId, setDurationId] = useState("");
  const [intervalId, setIntervalId] = useState("");
  const [timedOut, setTimedOut] = useState(false);
  let [payConfirmFlag, setPayConfirmFlag] = useState(true);
  const [headers, setHeaders] = useState('');
  const [invalidEmail, setInvalidEmail] = useState('');
  const [emailSuccess, setEmailSuccess] = useState('');
  const [emailFailed, setEmailFailed] = useState('');
  const [emailString, setEmailString] = useState('');
  const [ECMType, setECMType] = useState('pyw_payment_link');
  const [isECM, setECM] = useState();
  let payconflag = true;
  const [callOC, setCallOC] = useState(false)
  const wsData = useSelector(state => state.wsPageData);
  const [isMember, setMember] = useState(false);
  const [analEvent, setAnalEvent] = useState("checkout");
  const [temp , setTemp] = useState("start")
  const handleSendEmail = () => {
    var element = document.getElementById('email');
    var emailbtn = document.getElementById('sendmail');
    setEmailSuccess('');
    setEmailFailed('');
    element.classList.remove("txt-valid");
    element.classList.add("txt-loader");
    let headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      platform: "PYW",
      merchantClientId: merchant
    };

    if (cookie.load("pywrefid") !== undefined) {
      headers.refid = cookie.load("pywrefid");
    }

    let requestHeaders = { headers }

    let requestBody = {
      type: ECMType,
      email: `${email}`,
      vars: {
        paymentLink: process.env.REACT_APP_PAYMENT_QR_LINK + `?id=${pmtId}&merchant=${merchant}`
      }
    }

    axios.post(process.env.REACT_APP_SEND_EMAIL,
      requestBody, requestHeaders
    ).then(resp => {
      if (resp.data && resp.data.response) {
        console.log('Send Email - ' + resp.data.response)
        element.classList.remove("txt-loader");
        if (resp.data.response == "Success") {
          element.classList.remove("txt-loader");
          element.classList.remove("txt-error");
          element.classList.add("txt-valid");
          emailbtn.classList.add("mt-16");
          setEmailSuccess('Email sent!');
        } else if ((resp.data.response == "Failed")) {
          element.classList.remove("txt-loader");
          element.classList.remove("txt-valid");
          element.classList.add("txt-error");
          emailbtn.classList.add("mt-16");
          setEmailFailed('Email not sent!');
        }
      } else {
        element.classList.remove("txt-loader");
        element.classList.add("txt-error");
        emailbtn.classList.add("mt-16");
        setEmailFailed('Email not sent!');

      }
    }).catch(err => {
      element.classList.remove("txt-loader");
      element.classList.add("txt-error");
      emailbtn.classList.add("mt-16");
      setEmailFailed('Email not sent!');

    });
  };

  const handleInputChange = ({ target }) => {
    setEmailSuccess('');
    setEmailFailed('');
    setEmailString('')
    var emailbtn = document.getElementById('sendmail');
    if (target.name === "email") {
      target.classList.remove('txt-error');
      target.classList.remove("txt-valid");
      if (target.value !== "" && isValidEmail(target.value)) {
        setInvalidEmail('');
        emailbtn.classList.remove('mt-16');
      } else {
        if (target.value === '') {
          setEmailString('(It will only be used on this occasion)')
          setInvalidEmail('');
          target.classList.remove('txt-error');
          emailbtn.classList.remove('mt-16');
        } else {
          setInvalidEmail('Invalid email address');
          target.classList.add('txt-error');
          emailbtn.classList.add('mt-16');

        }
      }
      setEmail(target.value);
    }
  };

  function isValidEmail(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  // const handleClearInterval = (flag) => {
  //   clearInterval(intervalId);
  //   clearInterval(durationId);
  //   for (let i = 0; i < 100; i++) {
  //     window.clearInterval(i);
  //   }
  //   if (flag)
  //     HandleWait();
  // };

  // const HandleWait = () => {
  //   let requestbody = {};
  //   let requestHeaders = { headers }
  //   if(wsData.confirmStatus !== undefined && wsData.confirmStatus == 'interim-order' ){
  //     WS_PARAMS.hsConfirmTimeout= process.env.REACT_APP_WS_HS_CONFIRM_TIMEOUT
  //     WS_PARAMS.hsConfirmTimeoutRequire=true
  //   }

  //   if (callOC && pageData.confirmStatus !== undefined && pageData.confirmStatus == 'interim-order' && payconflag) {
  //     payconflag = false;
  //     setIntervalId(setInterval(() => {
  //       dispatch(orderConfirmationDetails(requestbody, requestHeaders, ""));
  //     }, parseInt(process.env.REACT_APP_HS_ORDERCON_INTERVAL)));
  //     setDurationId(setInterval(() => {
  //       setInterval(handleClearInterval(true));
  //     }, parseInt(process.env.REACT_APP_HS_INTERIM_WAITING_DURATION)));
  //   } else {
  //     setTimedOut(true);
  //     handleClearInterval(false);
  //   }
  // };

  useEffect(() => {
    if(wsData.confirmStatus !== undefined && wsData.confirmStatus == 'interim-order' 
        && WS_PARAMS.hsConfirmTimeout===""){
        
        WS_PARAMS.url = process.env.REACT_APP_SOCKET_URL+"?s=techhub&id="+cookie.load("pywid");
        WS_PARAMS.hsConfirmTimeout= process.env.REACT_APP_WS_HS_CONFIRM_TIMEOUT
        WS_PARAMS.hsConfirmTimeoutRequire=true
        dispatch(fetchWebSocketDetails(WS_PARAMS));

    }
    
  }, [wsData]);



  useEffect(() => {
    alert('Payment QR Code!');
    const queryString = require("query-string");
    const values = queryString.parse(props.location.search);
    setPmtId(values.id)
    setEmailString('(It will only be used on this occasion)')
    if (cookie.load("isECM") !== undefined) {
      if (cookie.load("isECM") === 'Y') {
        setECM('Y')
        setECMType('pyw_payment_link');
      } else {
        setECM('N')
        setECMType('pyw_payment_link_non_ecm');
      }
    }

    let requestbody = {}
    let requestHeaders = {};
    let headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW",
    };

    if (cookie.load("pywid") !== undefined) {
      headers.cartId = cookie.load("pywid");
    }

    if (cookie.load("pywrefid") !== undefined) {
      headers.refid = cookie.load("pywrefid");
    }

    if (cookie.load("pywmerchant") !== undefined) {
      headers.merchantClientId = cookie.load("pywmerchant");
      setMerchant(cookie.load("pywmerchant"));
    }
    if (cookie.load("isMember") !== undefined) {
      setMember(cookie.load("isMember"));
    }

    const cartId = headers.cartId;
    
    WS_PARAMS.url = process.env.REACT_APP_SOCKET_URL+"?s=techhub&id="+cartId
    WS_PARAMS.timeout= process.env.REACT_APP_WS_HS_PAYMENT_STATUS_TIMEOUT
    WS_PARAMS.timeoutRequire= true
    WS_PARAMS.hsCall= true
    sessionStorage.setItem("reconnectCount" , process.env.REACT_APP_WS_RECONNECT_MAX_COUNT)
    dispatch(fetchWebSocketDetails(WS_PARAMS));

    setPaymentLink(process.env.REACT_APP_PAYMENT_LINK + `?id=` + values.id + `&merchant=` + headers.merchantClientId)
    setQRLink(process.env.REACT_APP_PAYMENT_QR_LINK + `?id=` + values.id + `&merchant=` + headers.merchantClientId)

    requestHeaders = {
      headers
    }

    setHeaders(headers);
    dispatch(orderConfirmationDetails(requestbody, requestHeaders, ""));
    //dispatch(orderConfirmationDetails(requestbody, requestHeaders));
    // if (callOC == true) {
    //   setIntervalId(setInterval(() => {
    //     dispatch(orderConfirmationDetails(requestbody, requestHeaders, ""));
    //   }, parseInt(process.env.REACT_APP_HS_ORDERCON_INTERVAL)));

    //   setDurationId(setInterval(() => {
    //     setInterval(handleClearInterval(true));
    //   }, parseInt(process.env.REACT_APP_HS_ORDERCON_WAITING_DURATION)));

    // }
  }, []);
  useEffect(()=>{
    if(wsData.autoClose.isAutoClose === "true" &&  sessionStorage.getItem("reconnectCount")>0){
    //if(wsData.autoClose.isAutoClose === "true"){
      const cartId = cookie.load("pywid");
      WS_PARAMS.url = process.env.REACT_APP_SOCKET_URL+"?s=techhub&id="+cartId
      WS_PARAMS.timeout= process.env.REACT_APP_WS_HS_PAYMENT_STATUS_TIMEOUT
      WS_PARAMS.timeoutRequire= true
      WS_PARAMS.hsCall= true
      sessionStorage.setItem("reconnectCount",sessionStorage.getItem("reconnectCount")-1)
      // console.log(sessionStorage.getItem("reconnectCount"));
      dispatch(fetchWebSocketDetails(WS_PARAMS));
    }

  },[wsData.autoClose])
  const reconnectWebsocket = () =>{
    // setTemp((prev)=>{
    //   return prev + "visibility test" + document.visibilityState
    // })
let requestbody = {}
    let requestHeaders = {};
    let headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW",
    };

    if (cookie.load("pywid") !== undefined) {
      headers.cartId = cookie.load("pywid");
    }

    if (cookie.load("pywrefid") !== undefined) {
      headers.refid = cookie.load("pywrefid");
    }

    if (cookie.load("pywmerchant") !== undefined) {
      headers.merchantClientId = cookie.load("pywmerchant");
      setMerchant(cookie.load("pywmerchant"));
    }
    if (cookie.load("isMember") !== undefined) {
      setMember(cookie.load("isMember"));
    }
    if(document.visibilityState === "visible"){
      const cartId = cookie.load("pywid");
      requestHeaders = {
        headers
      }
  
      setHeaders(headers);
      dispatch(orderConfirmationDetails(requestbody, requestHeaders, ""));


      // setTemp((prev)=>{
      //   return prev + "inside if" + cartId
      // })
      // const cartId = headers.cartId;
      WS_PARAMS.url = process.env.REACT_APP_SOCKET_URL+"?s=techhub&id="+cartId
      WS_PARAMS.timeout= process.env.REACT_APP_WS_HS_PAYMENT_STATUS_TIMEOUT
      WS_PARAMS.timeoutRequire= true
      WS_PARAMS.hsCall= true
      dispatch(fetchWebSocketDetails(WS_PARAMS));
    }
  }
  useEffect(()=>{
    window.addEventListener("visibilitychange",reconnectWebsocket)
    return ()=>{
      window.removeEventListener ("visibilitychange",reconnectWebsocket)
    }
  },[])
  const setEventAlert = () => {
    if (isECM == 'Y') {
      setAnalEvent("checkout_decline_ecm");
    } else if (isECM == 'N') {
      setAnalEvent("checkout_decline_nonecm");
    }
    alert('Offer Declined!');
  };

  if (pageData.shsLoading) {
    return (
      <section class="container loading-card mt-32">
        <div class="loader" />
        <h5 class="mt-16">{LOADING}</h5>
      </section>)
  }
  else if (wsData.apiError) {
   
    return (
      <section class="container loading-card mt-32">
        <div class="loader" />
        <h5 class="mt-16">{LOADING}</h5>
      </section>)
  } else if ((wsData.confirmStatus !== undefined && wsData.confirmStatus == 'confirm') 
  || (pageData.confirmStatus !== undefined && pageData.confirmStatus === 'confirm')) {
    //handleClearInterval(false);
    return <Redirect to={`/shsstatus`} push={true} />
  } else if ((wsData.confirmStatus !== undefined && wsData.confirmStatus == 'cancel') 
  ||(pageData.confirmStatus !== undefined && pageData.confirmStatus === 'cancel')) {
    //handleClearInterval(false);
    return <Redirect to={`/shscanstatus`} push={true} />
  } else if (wsData.customDisconnect.customClose === "true") {
    //handleClearInterval(false);
    return <Redirect to={`/shsonhold`} push={true} />
  } else if (wsData.confirmStatus === "confirm") {
    return <Redirect to={`/shsstatus`} push={true} />
  } else if (wsData.confirmStatus === 'cancel') {
    return <Redirect to={`/shscanstatus`} push={true} />
  }
  else {
    return (
      <main className="main-container">
        <section class="container">
          {(() => {
            if (pageData.responseData !== undefined && pageData.responseData !== '') {
              return (<>
              {/* <PywTealiumAnalytics response={pageData} tealiumEvent={analEvent}  ecm={isECM}/> */}
              
              <AnalyticsWrapper
					 
					triggerType="page"
					event={analEvent}
					data={pageData}
				/>
              </>);
            }
          })() }
          {(() => {
            if ((pageData.confirmStatus !== undefined && pageData.confirmStatus === 'interim-order')
              || (pageData.confirmStatus === 'cart')
              || (wsData.confirmStatus === 'cart')
              || wsData.confirmStatus == 'interim-order') {
              // if (payConfirmFlag && pageData.confirmStatus !== 'cart') {
              //   setPayConfirmFlag(false);
              //   //handleClearInterval(true);
              // }
              if ( (wsData.confirmStatus === 'cart' || wsData.confirmStatus === 'interim-order')) {
                cookie.save('isMember', true, { maxAge: parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain: '.shopyourway.com', path: '/' });
                return (
                  <div  class="price-detail shadow" style={{ borderRadius: "10px" }}>
                    <dl class="price-detail-item">
                      <dt>
                        <div class="loader" style={{ height: "30px", width: "30px" }} />
                        <h4 class="ff-open-sans ml-24 hs-shsdesc-wfpc">Waiting for payment confirmation...</h4>
                      </dt>
                    </dl>
                  </div>
                )
              }
            }
          })() }
          <header class="main-header sh mt-8">
            <h4 class="gotham-font-style white hs-shsdesc-pwsyw">Pay with Shop Your Way</h4>
          </header>
          <div class="price-detail shadow" style={{ borderRadius: "0 0 5px 5px" }} >
            <div class="container-sh">
              <dl class="center mt-8">
                {(() => {
                  if (pmtId !== undefined || pmtId !== '') {
                    return (
                      <div>
                        {(() => {
                          if (isECM == 'Y') {
                            return <p className="ff-open-sans hs-shsdesc-stjaap">Scan this code to<br/> Pay with Shop Your Way</p>
                          } else {
                            return <p className="ff-open-sans hs-shsdesc-stjaap">Scan to Join, Apply and Pay</p>
                          }
                        })() }
                        <br/>
                        {/* <p>{temp}</p> */}
                        <QRCodeSVG style={{ height: '210px', width: '210px' }} value={QRLink} />
                        <div class="separator-nw">
                          <span className="ff-open-sans fs-14">or</span>
                        </div>
                      </div>
                    );
                  }
                })() }
                <p className="ff-open-sans hs-shsdesc-stjaap">Enter an email address<br/></p>
                <h5 className="ff-open-sans hs-shsdesc-st-opt fs-16">(It will only be used on this occasion) </h5>
              </dl>
              <label class="label-email mr-16">
                <input id="email" class="input-email" onChange={handleInputChange} name="email" autocomplete="off" required/>
                <span class="span-email">name@mail.com</span>
              </label>
              <span>
                <small class="red alert-sh show">{invalidEmail}</small>
                <small class="green alert-sh show ml-0">{emailSuccess}</small>
                <small class="red alert-sh show ml-0">{emailFailed}</small>
              </span>
              <button id="sendmail" class="btn sh-btn-primary" onClick={handleSendEmail} disabled={!isValidEmail(email) }>
                <img  src={images.Email}  class="mr-12"/><span class="gotham-font-style hs-shs-btn-yes-decline"> Send Email</span>
              </button>
              <div class="hs-shs-desc-line-separator-full  mt-24"></div>
              <dt>
                <p class="ff-open-sans grey60 mb-6 mt-16 hs-shs-desc-total-url lh-24"><b>TOTAL</b></p>
              </dt>
              <dt>
                <span class="ff-open-sans font-24 pb-16 lh-32 fw-700 title-black">{pageData.summary.currency}<span class='mr-2'/>{pageData.summary.priceSubTotal}</span>
              </dt>
              <dt>
                <p class="ff-open-sans grey60 mb-6 hs-shs-desc-total-url lh-24"><b>URL (For Manual Entry) </b></p>
              </dt>
              <dt>
                <b className="ff-open-sans hs-shsdesc-url break">{paymentLink}</b>
              </dt>
              <button class="btn btn-secondary mt-24 white" onClick={showHSDeclineBackdrop}>
                <span className="gotham-font-style hs-shs-btn-yes-decline hs-shsdesc-decline">Decline</span>
              </button>
            </div>
          </div>
        </section>
        <PywModalHSDeclineConfimation props={pageData} setAnalEvent={setAnalEvent} isECM={isECM}/>
      </main>
    )
  }
}
export default Pyw_Description;
