import React, { useState, useEffect } from "react";
import { images } from "../pywImagesComponent/PywImages";
import { useSelector, useDispatch } from "react-redux";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import cookie from "react-cookies";
import PywError from"../pywErrorComponent/PywError";
import { LOADING } from "../pywConstantComponent/PywConstant"
import { orderConfirmationDetails } from "../redux";
import { Redirect } from "react-router-dom";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";
const Pyw_OnHold = (props) => {
  const dispatch = useDispatch();
  const pageData = useSelector(state => state.pageData);
  const [alertFlag , setAlertFlag] = useState(false);
  const checkCurrentStatus = () => {
    let requestbody = {}
    let requestHeaders = {};
    let headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW",
      //isLight:"Y"
    };

    if (cookie.load("pywid") !== undefined) {
      headers.cartId = cookie.load("pywid");
    }

    if (cookie.load("pywrefid") !== undefined) {
      headers.refid = cookie.load("pywrefid");
    }

    if (cookie.load("pywmerchant") !== undefined) {
      headers.merchantClientId = cookie.load("pywmerchant");
    }

    requestHeaders = {
      headers
    }

    dispatch(orderConfirmationDetails(requestbody, requestHeaders));
  }

  useEffect(() => {
    checkCurrentStatus()
  }, []);



if (pageData.pageLoading) {
    return (
      <section class="container loading-card mt-32">
        <div class="loader" />
        <h5 class="mt-16">{LOADING}</h5>
      </section>)
  } else if (pageData.confirmStatus !== undefined && pageData.confirmStatus == 'confirm') {
    return <Redirect to={`/shsstatus`} push={true} />
  } else if (pageData.confirmStatus !== undefined && (pageData.confirmStatus == 'cancel' || pageData.confirmStatus == 'cart')) {
    return <Redirect to={`/shscanstatus`} push={true} />
  } else if(pageData.confirmStatus !== undefined && pageData.confirmStatus == 'interim-order') {
    if(alertFlag == false){
      setAlertFlag(true);
      alert('Payment On-hold!');
    }
    return (
      <main className="main-container mt-24">
        <section class="container">
          {/* <PywTealiumAnalytics response={pageData} tealiumEvent="onhold_payment" /> */}
          <AnalyticsWrapper
					 
					triggerType="page"
					event="onhold_payment"
					data={pageData}
				/>
          <div class="price-detail shadow  mb-16" style={{borderRadius:"6px"}}>
            <div class="sh-pay-confirm pt-16 pb-16">
              <img class="mb-16 mt-16" src={images.NoCreditCardBlue} alt="" style={{ height: '43px', width: '45px' }}/>
              <span class="ff-open-sans sh-status ml-8">Payment On-Hold!</span>
              <a class="info-link-reload" onClick={checkCurrentStatus}><img src={images.reload} /></a>
            </div>
          </div>
        </section>
        <div class="container-footer">
          <button class="gotham-font-style btn sh-btn-primary " onClick={() => alert('Exit-Checkout!') }>
            <span className="gotham-font-style hs-shs-btn-yes-decline">Exit to checkout</span>
          </button><br/>
         </div>
      </main>
    )
}else{
    return <div></div>
  }
}
export default Pyw_OnHold;