import React, { useState} from "react";
import { images } from "../pywImagesComponent/PywImages";
import { headerPyw } from "../pywConstantComponent/PywDesignsConstant";
const Pyw_PaymentLinkIssue = (props) => {
    
    return (
        <main>
             {headerPyw()}
            <section class="container container-wrapper">
               <br/>
               <br/><br/>
               <br/>
                <div  class="center">
                    <img src={images.cardError} alt=""/>
                </div>
                <br/>
                <br/>
                <h2 class="center">Unfortunately, it look like there's a<br/>problem with your payment link.</h2>
                <br/>
                <h4 class="grey60 center">Please request a new link from your<br/>representative. </h4>

            </section>
            <br/><br/><br/>
            <footer>               
                <p class="powered"><img src={images.lock} alt=""/> Powered by Shop Your Way</p>
            </footer>
        </main>
    )
}
export default Pyw_PaymentLinkIssue;
