import React, { useState, useEffect } from "react";
import { headerPyw, headerUserWithPoints,loading } from "../pywConstantComponent/PywDesignsConstant";
import { images } from "../pywImagesComponent/PywImages";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "../pywUtilComponent/PywRedirect.ts";
import {headers, PYWCCERROR } from "../pywConstantComponent/PywConstant"
import cookie from "react-cookies";
import * as duui from 'device-uuid'
import { fetchPrepareDetails } from "../redux";
import PywError from "../pywErrorComponent/PywError";

const Pyw_IncorrectDetails = (props) => {
    const { user } = useAuth0();
    const pageData = useSelector(state => state.pageData);
    const [userInfo, setUserInfo] = useState("");
    const dispatch = useDispatch();
    const [backPageFlag, setBackPageFlag] = useState(false);

    const redirectToCustom = () => {
        setBackPageFlag(true);
    }

    useEffect(() => {
        const userJson = JSON.stringify(user);
        const userNew = userJson.split(process.env.REACT_APP_CLAIMS_URL).join("");
        const user_json = JSON.parse(userNew);
        setUserInfo(user_json);
        const refid = cookie.load("pywrefid");
        const items = [];
        let requestBody = { items };
        headers.cartId = cookie.load("pywcartId");
        headers.refid = cookie.load("pywrefid");
        headers.merchantClientId = cookie.load("pywmerchant");
        headers.pywstate = PYWCCERROR + "~~" + cookie.load("at0pyw") + duui.DeviceUUID().get();
        const requestHeaders = {
            headers
        };
        dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
    }, []);

    if (backPageFlag) {
        return <Redirect to="/pyw/custom/summary" push={true} />
    }

    if (pageData.pageError) {
        return <PywError pageData={pageData} />
    } else if (pageData.pageLoading) {
        return (loading())
    } else if (pageData.isValid) {
        return (
            <main className="gotham-font-style flex-container">
                {headerPyw()}
                <section>
                    {headerUserWithPoints(pageData, userInfo)}
                    <div class="container container-wrapper message-incorrect" >
                        <div class="center">
                            <img src={images.NoCreditCard} alt="" />
                        </div >
                        <div className="mt-32">
                        <p class="title-grey center fs-16 lh-24">Sorry, your credit card was declined.<br />
                            Please try again or use another card.</p>
                        </div>
                    </div>
                </section >
                <footer className="container-wrapper-without-padding pt-100">
                    <button class="btn btn-primary" id="payButton" onClick={redirectToCustom}>Try Again</button>
                </footer>
            </main >
        )
    } else { return (<div />) }
}

export default Pyw_IncorrectDetails;