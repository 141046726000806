import React from 'react';
import {useAuth0} from '@auth0/auth0-react';


const LoginButton = (props) => {
    const {loginWithRedirect} = useAuth0();
    if (props.type == 'primary') {
        return <button class="btn btn-primary " onClick={() =>loginWithRedirect({screen_hint: "signup"})}><b>{props.children}</b></button>
    } else
        return <button class="btn btn-secondary h-52" onClick={() => loginWithRedirect({screen_hint: "login"})} ><b>{props.children}</b></button>
}
export default LoginButton;
