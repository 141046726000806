import { images } from "../pywImagesComponent/PywImages";
import { LOADING } from "./PywConstant";
import { loginHandler } from "../pywUtilComponent/PywLoginUtil";
import { disableButtonClick } from "../redux";
export function headerPyw() {
    return (
        <div>
            <header className="main-header">
                <h5 className="white headline fw-700">Pay with Shop Your Way</h5>
            </header>
        </div>
    );
}

export function headerMerchant(pageData) {
    return (
        <div className="merchant h-47">
            <div className="merchant-image">
                {(() => {
                    if (pageData.cartResponseData.merchantLogoUrl) {
                        return (
                            <img src={pageData.cartResponseData.merchantLogoUrl} alt="Merchant Icon" className="avatar_round" />
                        )
                    }
                })()}
                <p className="title-grey word-break fs-16 fw-400 lh-20">{pageData.merchantName}</p>
            </div>
            <div className="merchant-amount">
                <img src={images.cart} alt="Cart Icon" className="card_icon" />
                <span className="title-grey fs-16 fw-400 lh-20">{pageData.summary.currency + pageData.summary.priceSubTotal}</span>

            </div>
        </div>
    )

}

export function headerUserWithPoints(pageData, userInfo) {
    return (
        <div className="user">
            <div className="user-image">
                <img src={userInfo.picture} alt="" />
                {pageData.ffm.map(ffm => {
                    if ("EMAIL" === ffm.type) {
                        return (<p className='fs-16 black fw-700 lh-20'>{ffm.firstName + " " + ffm.lastName}</p>)
                    }
                })}
            </div>
            {
                pageData?.tenderTypes?.includes("SYW_POINTS") && (
                <div className="counter-points">
                    <div className="coin-logo">
                        <img className="path-img" src={images.Path} alt="" />
                    </div>
                    <span className="semi-light-blue ml-7 fw-600">{pageData?.sywPoints?.currentPointsBalance ? pageData?.sywPoints?.currentPointsBalance : "0"}</span>
               </div>
                )
            }
            
        </div>

    )
}

export function headerUserWithPointsGuest(pageData, loginWithRedirect, dispatch) {
    let emailId = "";
    return (
        <div className="user">
            <div className="user-image">
                <img src={images.user} alt="" />
                {pageData.ffm.map(ffm => {
                    if ("EMAIL" === ffm.type) {
                        emailId = ffm.emailId;
                        return (<p className='fs-15 lh-16 black fw-400'>{ffm.emailId}</p>)
                    }
                })}
            </div>
            <div className="login-link fs-14 lh-16">
                <a onClick={e => { dispatch(disableButtonClick()); loginHandler(emailId, loginWithRedirect) }}>Login or Create account</a>
            </div>
        </div>
    )
}
export function headerUsernameGuestWithPoints(pageData) {
    let emailId = "";
    return (
        <div className="user">
            <div className="user-image">
                <img src={images.user} alt="" />
                {pageData.ffm.map((ffm) => {
                   if ("EMAIL" === ffm.type) {
                        emailId = ffm.emailId;
                        return (<p className='fs-15 lh-16 black fw-400'>{ffm.emailId}</p>)
                    }
                })}
            </div>

            <div className="counter-points">
                <div className="coin-logo">
                    <img className="path-img" src={images.Path} alt="" />
                </div>
                <span className="semi-light-blue ml-7 fw-600">
                    {0}
                </span>
            </div>
        </div>
    );
}
export function headerUsernameGuestWithLogin(
    pageData,
    loginWithRedirect,
    dispatch
) {
    let emailId = "";
    return (
        <div className="user">
            <div className="user-image">
                <img src={images.user} alt="" />
                {pageData.ffm.map((ffm) => {
                    if ("EMAIL" === ffm.type) {
                        emailId = ffm.emailId;
                        return <p className="fs-16 lh-20 black fw-600">{emailId}</p>;
                    }
                })}
            </div>
            <div className="login-link fs-14 lh-16">
                <a
                    onClick={(e) => {
                        dispatch(disableButtonClick());
                        loginWithRedirect({ screen_hint: "login", login_hint: emailId });
                    }}
                >
                    Login
                </a>
            </div>
        </div>
    );
}


export function GuestHeaderBar(pageData,loginWithRedirect, dispatch) {
    if (pageData.cartResponseData?.summary?.sywr?.isExistingMember === 'Y') {
        return (
            <>
                {headerUsernameGuestWithLogin(pageData, loginWithRedirect, dispatch)}
                <div className="price-detail shadow  mb-20">
                    {headerMerchant(pageData)}
                    {headerSywPointsGuest()}
                    {(() => {
                        if (pageData.summary.totalDiscountAmount !== "0.00" && pageData.summary.totalDiscountAmount !== undefined) {
                            return savingHeader(pageData)
                        }
                    })()}
                    {headerOutStandingBalance(pageData)}
                </div>
            </>
        );
    } else {
        return (
            <>
                {headerUsernameGuestWithPoints(pageData)}
                <div className="price-detail shadow  mb-20">
                    {headerMerchant(pageData)}
                    {(() => {
                        if (pageData.summary.totalDiscountAmount !== "0.00" && pageData.summary.totalDiscountAmount !== undefined) {
                            return savingHeader(pageData)
                        }
                    })()}
                    {headerOutStandingBalance(pageData)}
                </div>
            </>
        );
    }
}
export function headerUserWithNoPointsGuest(pageData) {
    let emailId = "";
    return (
        <div className="user">
            <div className="user-image">
                <img src={images.user} alt="" />
                {pageData.ffm.map(ffm => {
                    if ("EMAIL" === ffm.type) {
                        emailId = ffm.emailId;
                        return (<p className='fs-16 lh-20 title-grey fw-700'>{ffm.emailId}</p>)
                    }
                })}
            </div>
        </div>
    )
}

export function headerSywPoints(pageData, setNavigate) {
    return (
        <dl className="price-detail-item pt-12 pb-11">
            {(() => {
                if (parseFloat(pageData?.sywPoints?.currentPointsBalance) !== 0.00) {
                    return (
                        <dt className='fs-14' style={{ color: "#262C2F" }}>Shop Your Way points.<a onClick={() => setNavigate(true)} className="ml-4 fw-700 fs-14 lh-20">Edit</a></dt>
                    );
                }
            })()}
            {(() => {
                if (parseFloat(pageData?.sywPoints?.currentPointsBalance) === 0.00) {
                    return (
                        <dt>Shop Your Way points.<a className="ml-4 disabled fw-700 fs-14 lh-20 title-message-grey">Edit</a></dt>
                    );
                }
            })()}
            {/* <dt>Shop Your Way points.<a className="ml-4 disabled title-message-grey">Edit</a></dt> */}
            <dd><span className="fs-14 fw-400 lh-20">-{pageData.summary.currency + pageData.summary.pointsDollarValue}</span></dd>
        </dl>
    )
}

export function headerSywPointsGuest() {
    return (
        <dl className="price-detail-item h-43">
            <dt style={{ color: "#5D686D", fontSize: "14px", fontWeight: "400", lineHeight: "20px" }}>Login to use existing SYW points</dt>
            <dd><span className="dark-grey fs-14 fw-400 lh-20">-$0.00</span></dd>
        </dl>
    )
}

export function headerOutStandingBalance(pageData) {
    return (
        <dl className="price-detail-item h-48 pt-12 pb-16">
            <dt><b className="title-grey fs-14 fw-700 lh-20">Outstanding Balance: </b></dt>
            <dd><span className="orange-updated fs-14 fw-700 lh-20">{pageData.summary.currency + pageData.summary.paymentDue}</span></dd>
        </dl>
    )
}
export function headerBar(value, pageData, setNavigate) {

    if (value === 1) {
        return (
            <div className="price-detail mb-40 shadow h-52">
                {headerMerchant(pageData)}
            </div>
        )
    }
    else if (value === 2) {
        return (
            <div className="price-detail shadow  mb-20">
                {headerMerchant(pageData)}
                {pageData?.tenderTypes?.includes("SYW_POINTS") && headerSywPoints(pageData, setNavigate)}
                {(() => {
                    if (pageData.summary.totalDiscountAmount !== "0.00" && pageData.summary.totalDiscountAmount !== undefined) {
                        return savingHeader(pageData)
                    }
                })()}
                {headerOutStandingBalance(pageData)}
            </div>
        )
    }
    else if (value === 3) {
        return (
            <div className="price-detail shadow  mb-20">
                {headerMerchant(pageData)}
                {pageData?.tenderTypes?.includes("SYW_POINTS") && headerSywPointsGuest()}
                {(() => {
                    if (pageData.summary.totalDiscountAmount !== "0.00" && pageData.summary.totalDiscountAmount !== undefined) {
                        return savingHeader(pageData)
                    }
                })()}
                {headerOutStandingBalance(pageData)}
            </div>
        )
    }
    else if (value === 4) {
        return (
            <div className="price-detail shadow  mb-20">
                {headerMerchant(pageData)}
                {headerOutStandingBalance(pageData)}
            </div>
        )
    }
}
export function progressBar(val) {
    if (val === 1)
        return <div className="progressbar mb-40">
            <div className="progressbar-step-select semi-light-blue fs-14 lh-20">
                1. Apply
            </div>
            <div className="progressbar-step-disable dark-grey fs-14 lh-20">
                2. Pay
            </div>
        </div>;
    else if (val === 2)
        return <div className="progressbar mb-40">
            <div className="progressbar-step-disable-left">
                <div className="dot">
                    <span className="checkmark">
                        <div className="checkmark_stem"></div>
                        <div className="checkmark_kick"></div>
                    </span>
                </div>
            </div>
            <div className="progressbar-step-select semi-light-blue fs-14">
                2. Pay
            </div>
        </div>;
}

export function brandMessageMember(pageData) {
    if (pageData.merchantDetailResponse && pageData.merchantDetailResponse.merchantDetails && pageData.merchantDetailResponse.merchantDetails.merchantMsg !== undefined &&
        Object.keys(pageData.merchantDetailResponse.merchantDetails.merchantMsg).length !== 0
        && pageData.merchantDetailResponse.merchantDetails.merchantMsg.MEMBER_SCREEN_MSG) {
        return <p className="mb-20 mt-20 title-grey member-brand-msg fw-400">
            {pageData.merchantDetailResponse.merchantDetails.merchantMsg.MEMBER_SCREEN_MSG}</p>
    }
    else {
        return <p className="mb-20 mt-20 title-grey member-brand-msg fw-400 lh-24">Apply for your Shop Your Way Mastercard®.</p>
    }
}

export function declineMsg(pageData) {
    return (
        <h4 className="mb-20 fs-16 fw-400 title-grey mt-20 lh-24">
            Join Shop Your Way and use a credit card to pay the remaining{" "}
            <span className="semi-light-blue">{pageData?.summary?.currency + pageData?.summary?.paymentDue}.</span>
        </h4>
    )
}
export function loading() {
    return <section className="container loading-card mt-32 h-166">
        <div className="loader" />
        <p className="mt-16">{LOADING}</p>
    </section>
}

export function savingHeader(pageData) {
    return (
        <dl className="price-detail-item ws_nwr">
            {
                pageData?.cartResponseData?.label?.promotion_label ? 
                <dt><b className="title-grey white-spc fs-14">{pageData?.cartResponseData?.label?.promotion_label}</b></dt> :
                <dt><b className="title-grey white-spc fs-14">Shop Your Way Mastercard<sup className="sup-without-fonts">®</sup> savings</b></dt>
            }
            
            <dd><span className="semi-light-blue fs-14" ><b>-{pageData.summary.currency + (pageData.summary.totalDiscountAmount === undefined ? "0.00" : pageData.summary.totalDiscountAmount)}</b></span></dd>
        </dl>

    )
}
