import { TRUE } from "../pywConstantComponent/PywConstant";
import { GTMAnalytics } from "./pywGTMAnalytics/Pyw_GTM_Analytics";
import PywTealiumAnalytics from "./PywTealiumAnalytics";

function AnalyticsWrapper({ triggerType, event, data }) {
	if (process.env.REACT_APP_GTM_ANALYTICS === TRUE) {
		GTMAnalytics(triggerType, event, data);
	}
	if (process.env.REACT_APP_TEALIUM_ANALYTICS === TRUE) {
		return <PywTealiumAnalytics response={data} tealiumEvent={event} />;
	}
	return null
}

export default AnalyticsWrapper;
