import React, { useEffect }from "react";
import { images } from "../pywImagesComponent/PywImages";
import '../assets/styles/css/main.css';
import '../assets/styles/css/spinner.css';
import { hideBackdrop, hideTermShowCitiBackdrop } from "../pywUtilComponent/PywUtil.js";
const PywModalBackdrop = (props) => {
  useEffect(() => { }, []);
  return (
    <div id="backdrop" class="backdrop fixed-position-backdrop">
      <div class="modal">
        <div class="modal-header">
          <div class="header-text">
            <p id="waitText" style={{ visibility: 'hidden' }}> Please wait..</p>
          </div>
          <button class="btn btn-text closeModal" onClick={e => { hideBackdrop(e) } }>
            <img src={images.close} alt="Close" />
          </button>
        </div>
        <section class="modal-body">
          <iframe id="iframeSeeDetails" src=""
            width="100%" height="430px" frameBorder="0"></iframe>
        </section>
        <div class="modal-footer">
          <div class="flex center">
            {(() => {
              if (props.btnflag === "backbtn") {
                return (
                  <button class="btn btn-primary" id="payButton" onClick={e => { hideBackdrop(e) } }>Back</button>)
              } else if (props.btnflag === "citibtn") return (<button class="btn btn-primary" id="payButton" onClick={e => { hideTermShowCitiBackdrop(e) } }>Apply & Buy</button>)
            })() }
          </div>
        </div>
      </div>
    </div>
  )
}
export default PywModalBackdrop;