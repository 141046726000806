import React, { useEffect } from "react";
import { images } from "../pywImagesComponent/PywImages";
import cookie from "react-cookies";
import { headers, PYWCONFIRMATION } from "../pywConstantComponent/PywConstant";
import * as duui from "device-uuid";
import { useSelector, useDispatch } from "react-redux";
import { orderConfirmationDetails } from "../redux";
import PywError from "../pywErrorComponent/PywError";
import { loading } from "../pywConstantComponent/PywDesignsConstant";
import { parseCardValue } from "../pywUtilComponent/PywUtil.js";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";

const Pyw_HomeServicesOrderConfirmation = (props) => {
	const dispatch = useDispatch();
	const pageData = useSelector((state) => state.pageData);

	useEffect(() => {
		const queryString = require("query-string");
		const values = queryString.parse(props.location.search);
		const cartId = values.pywid;
		// const refid = values.pywrefid;
		const refid = sessionStorage.getItem("pywrefid");
		// const refid = window.opener === null ? encodeURIComponent(values.pywrefid) : values.pywrefid;;
		// const statusType = values.pywmsg;

		let requestBody = {};

		headers.cartId = cartId;
		headers.merchantClientId = sessionStorage.getItem("pywmerchant");
		headers.refid = refid;
		// headers.pywstate = PYWCONFIRMATION + "~~" + sessionStorage.getItem("at0pyw") + duui.DeviceUUID().get();
		const requestHeaders = {
			headers,
		};
		dispatch(orderConfirmationDetails(requestBody, requestHeaders, ""));
	}, []);

	if (pageData.pageError) {
		return <PywError pageData={pageData} />;
	} else if (pageData.pageLoading) {
		return loading();
	} else if (pageData.confirmStatus) {
		return (
			<main className="cl-order-confirm gotham-font-style">
				<header></header>
				<section>
					{/* <PywTealiumAnalytics response={pageData} tealiumEvent="purchase" eventValue="techhub_order_confirmation" /> */}
					<AnalyticsWrapper
						triggerType="page"
						event="purchase"
						data={{
							...pageData?.responseData,
							memberNumber: pageData?.responseData?.memberNumber,
							transaction_id: pageData?.responseData?.omsId,
						}}
					/>
					<div className="container mt-40">
						<div>
							<div className="paymentLogo">
								<div className="syw-vector">
									<div className="syw-vector-text">
										SHOP YOUR WAY<sup>®</sup>
									</div>
								</div>
								{(() => {
									if (pageData.cartResponseData.merchantLogoUrl) {
										return (
											<div class="ml-m4 ovel-backdrop">
												<img
													src={pageData.cartResponseData.merchantLogoUrl}
													alt="Merchant Icon"
													class="avatar_round"
												/>
											</div>
										);
									}
								})()}
							</div>
							<div className="paymentSuccessMessage">
								<b>Payment Successful!</b>
							</div>
							{(() => {
								if (
									pageData.summary.earnPointSummary !== undefined &&
									parseInt(pageData.summary.earnPointSummary.totalEarnPoints) >
										0
								) {
									return (
										<div className="selection-card no-select mt-39">
											<h4 className="fs-14">
												You earned
												<span className="semi-light-blue padding-6">
													<b className="fs-xl">
														{pageData.summary.earnPointSummary.totalEarnPoints}
													</b>
												</span>
												Shop Your Way points!
											</h4>
										</div>
									);
								} else {
									return (
										<span>
											<br />
											<br />
											<br />
											<br />
										</span>
									);
								}
							})()}
							<p className="mt-12 paymentSuccessNote">
								Note: We'll also email you the details of this transaction
								shortly.
							</p>

							<div className="mt-39">
								<b>Your purchase:</b>
								<div className="purchaseDetails">
									<div className="purchaseDetails-title">
										{pageData.summary.items.map((tender) => {
											return tender.name ? tender.name : pageData.merchantName;
										})}
									</div>
									<div className="purchaseDetails-amount">
										{pageData.summary.currency + pageData.summary.priceSubTotal}
									</div>
								</div>

								<div className="fullSeparator"></div>

								<div className="purchaseDetails">
									<div className="purchaseDetails-title">
										Shop Your Way points
									</div>
									<div className="purchaseDetails-amount">
										-
										{pageData.summary.currency +
											pageData.summary.pointsDollarValue}
									</div>
								</div>

								<div className="fullSeparator"></div>

								<div className="purchaseDetails">
									<div className="purchaseDetails-title">
										<b>Order total:</b>
									</div>
									<div className="purchaseDetails-amount semi-light-blue fonts-bold">
										{pageData.tenders.map((tender) => {
											if ("CC_DC" === tender.tenderType) {
												return pageData.summary.currency + tender.amountPayable;
											}
										})}
									</div>
								</div>
							</div>
							{pageData.tenders.map((tender) => {
								if (
									"CC_DC" === tender.tenderType &&
									parseFloat(tender.amountPayable) > 0
								) {
									return (
										<div className="mt-39">
											<b>Payment information:</b>
											{(() => {
												if (
													tender.creditCartTypeCode &&
													tender.creditCartTypeCode === "I"
												) {
													return (
														<div className="paymentInformation mt-16">
															<img src={images.masterCardStright} />
															<div className="description">
																<h4>
																	Shop Your Way Mastercard<sup>®</sup>
																</h4>
																<p className="paymentSuccessNote">
																	ending in {parseCardValue(tender.cardNumber)}
																</p>
															</div>
														</div>
													);
												} else {
													return (
														<div className="mt-32 mb-16">
															<p className="mb-8">
																<span className="semi-light-blue">
																	{pageData.summary.currency +
																		tender.amountPayable}
																</span>{" "}
																was put on your card ending in{" "}
																{parseCardValue(tender.cardNumber)}.
															</p>
														</div>
													);
												}
											})()}
										</div>
									);
								} else if (
									"CC_DC" === tender.tenderType &&
									parseFloat(tender.amountPayable) === 0
								) {
									return (
										<span>
											<br />
											<br />
											<br />
											<br />
										</span>
									);
								}
							})}
						</div>
					</div>
				</section>
				<footer>
					{(() => {
						if (
							(window.opener &&
								window.opener !== window &&
								!window.menubar.visible) === null
						) {
							return (
								<p>
									<div class="close_msg dark-grey">
										<svg
											className="mr-8"
											stroke="currentColor"
											fill="currentColor"
											stroke-width="0"
											viewBox="0 0 1024 1024"
											height="2em"
											width="2em"
										>
											<path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
											<path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
										</svg>{" "}
										Now you can close the browser
									</div>
								</p>
							);
						} else {
							return (
								<button
									className="btn btn-primary"
									id="payButton"
									onClick={() => window.close()}
								>
									Close
								</button>
							);
						}
					})()}
					<p className="powered">
						<img src={images.lock} alt="" /> Powered by Shop Your Way
					</p>
				</footer>
			</main>
		);
	} else return <div></div>;
};

export default Pyw_HomeServicesOrderConfirmation;
