import {
	FETCH_PREPARE_REQUEST,
	FETCH_PREPARE_SUCCESS,
	FETCH_PREPARE_FAILURE,
	TRANSFER_CART_REQUEST,
	TRANSFER_CART_SUCCESS,
	TRANSFER_CART_FAILURE,
	ORDER_CONFIRMATION_REQUEST,
	ORDER_CONFIRMATION_SUCCESS,
	ORDER_CONFIRMATION_FAILURE,
	ORDER_CONFIRMATION_LIGHT_SUCCESS,
	FETCH_ORDERCONFIRMATION_SUCCESS_ERROR,
	FETCH_APPLYPOINTS_REQUEST,
	FETCH_APPLYPOINTS_SUCCESS,
	FETCH_APPLYPOINTS_SUCCESS_ERROR,
	FETCH_APPLYPOINTS_FAILURE,
	FETCH_PLACEORDER_REQUEST,
	FETCH_PLACEORDER_SUCCESS,
	FETCH_PLACEORDER_FAILURE,
	FETCH_PLACEORDER_SUCCESS_ERROR,
	FETCH_CREDITCARD_REQUEST,
    FETCH_CREDITCARD_SUCCESS,
    FETCH_CREDITCARD_FAILURE,
	FETCH_CREDITCARD_SUCCESS_ERROR,
	ANALYTICS_PREPARECHECKOUT_ERROR_CODE,
	ANALYTICS_APPLYPOINTS_ERROR_CODE,
	ANALYTICS_CREDITCARD_ERROR_CODE,
	ANALYTICS_PLACEORDER_ERROR_CODE,
	ANALYTICS_TRANSFER_ERROR_CODE,
	ADD_SUBSCRIPTION_REQUEST,
	ADD_SUBSCRIPTION_SUCCESS,
	ADD_SUBSCRIPTION_SUCCESS_ERROR,
	ADD_SUBSCRIPTION_FAILURE,
	GENERATE_PAYMENT_REQUEST,
	GENERATE_PAYMENT_SUCCESS,
	GENERATE_PAYMENT_FAILURE,
	GET_SDKCONTENT_REQUEST,
	GET_SDKCONTENT_SUCCESS,
	GET_SDKCONTENT_SUCCESS_ERROR,
	GET_SDKCONTENT_FAILURE,
	VALIDATE_EMAIL_REQUEST,
	VALIDATE_EMAIL_SUCCESS,
	VALIDATE_EMAIL_SUCCESS_ERROR,
	VALIDATE_EMAIL_FAILURE,
	DISABLE_BUTTON_CLICK_TRUE,
	ENABLE_BUTTON_CLICK_TRUE,
	VERIFY_EMAIL_FAILURE, VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_SUCCESS_ERROR, DISABLE_INPUTS, ENABLE_INPUTS
} from './pywMemberDetailsTypes'

const initialState = {
	pageLoading: false,
	items: '',
	ffm: '',
	summary: '',
	tenders: '',
	sywPoints: '',
	ccDc: '',
	leasing: '',
	quardsPay: '',
	pageError: '',
	cartId: '',
	isValid: false,
	pageApiError: null,
	applyPointsFailure: '',
	orderCompleteResponseCode: '',
	orderPlacedResponse: '',
	pointApplySuccesfully:false,
	merchantName:'',
	memberNumber:'',
    emailHash:'',
	sywrUserId:'',
	ANALYTICS_PREPARECHECKOUT_ERROR_CODE,
    ANALYTICS_APPLYPOINTS_ERROR_CODE,
    ANALYTICS_PLACEORDER_ERROR_CODE,
    ANALYTICS_CREDITCARD_ERROR_CODE,
	orderConfirmationResponseCode:'',
	responseData:'',
	confirmStatus:'',
	response:'',
	cartResponseData: '',
	validateEmailResponse: '',
	isEmailValid: '',
	isShowLoader: '',
	isDisableButtonClick: false,
	sendEmailResponse:"",
	inputsDisabled:false,
	applyButtonDisabled:false,
	continuePaymentButtonDisabled:false,
	resendEmailButtonDisabled:false
}

const reducer = (state = initialState, action) => {
	let cc_dc_temp = undefined;
	let syw_points_temp = undefined;
	let quardsPay_temp = undefined;
	let leasing_temp = undefined;
	if (action.payload !== undefined && action.payload.tenders !== undefined) {
		const tender_temp = action.payload.tenders;


		{
			tender_temp.map(tender => {
				if ("CC_DC" === tender.tenderType) {
					cc_dc_temp = tender;
				}
				if ("SYW_POINTS" === tender.tenderType) {
					syw_points_temp = tender;
				}
				if ("QUADSPAY" === tender.tenderType) {
					quardsPay_temp = tender;
				}
				if ("LEASING" === tender.tenderType) {
					leasing_temp = tender;
				}
			});
		}
	}

	switch (action.type) {
		case FETCH_PREPARE_REQUEST:
			return {
				...state,
				pageLoading: true,
				shsLoading: true,
				pointApplySuccesfully:false,
			}
		case FETCH_PREPARE_SUCCESS:
			return {
				...state,
				pageLoading: false,
				shsLoading: false,
				items: action.payload.items,
				ffm: action.payload.ffm,
				summary: action.payload.summary,
				tenders: action.payload.tenders,
				tenderTypes : action.payload.tenders.map((tender)=> {return tender.tenderType}),
				ccDc: cc_dc_temp,
				sywPoints: syw_points_temp,
				quardsPay: quardsPay_temp,
				leasing: leasing_temp,
				pageError: '',
				cartId: action.payload.cartId,
				isValid: action.payload.isValid,
				pointApplySuccesfully:false,
				merchantName:action.payload.merchantName,
				memberNumber:action.payload.memberNumber,
    			emailHash:action.payload.emailHash,
				sywrUserId:action.payload.sywrUserId,
				addlAttrs: action.payload.addlAttrs,
				cartResponseData: action.payload
			}
		case FETCH_PREPARE_FAILURE:
			return {
				pageLoading: false,
				shsLoading: false,
				items: '',
				ffm: '',
				summary: '',
				tenders: '',
				ccDc: '',
				sywPoints: '',
				quardsPay: '',
				leasing: '',
				cartId: '',
				isValid: false,
				pageError: action.payload,
				pointApplySuccesfully:false,
				memberNumber:'',
    			emailHash:'',
				sywrUserId:'',
				analyticsAPIErrorCode:ANALYTICS_PREPARECHECKOUT_ERROR_CODE,
			}

		case TRANSFER_CART_REQUEST:
			return {
				...state,
				pageLoading: true,
				isShowLoader: true
			}
		case TRANSFER_CART_SUCCESS:
			return {
				...state,
				pageLoading: false,
				items: action.payload.items,
				ffm: action.payload.ffm,
				summary: action.payload.summary,
				tenders: action.payload.tenders,
				tenderTypes : action.payload.tenders.map((tender)=> {return tender.tenderType}),
				ccDc: cc_dc_temp,
				sywPoints: syw_points_temp,
				quardsPay: quardsPay_temp,
				leasing: leasing_temp,
				pageError: '',
				cartId: action.payload.cartId,
				isValid: action.payload.isValid,
				merchantName:action.payload.merchantName,
				memberNumber:action.payload.memberNumber,
    			emailHash:action.payload.emailHash,
				sywrUserId:action.payload.sywrUserId,
				addlAttrs: action.payload.addlAttrs,
				cartResponseData: action.payload
			}
		case TRANSFER_CART_FAILURE:
			return {
				pageLoading: false,
				items: '',
				ffm: '',
				summary: '',
				tenders: '',
				ccDc: '',
				sywPoints: '',
				quardsPay: '',
				leasing: '',
				cartId: '',
				isValid: false,
				pageError: action.payload,
				memberNumber:'',
    			emailHash:'',
				sywrUserId:'',
				analyticsAPIErrorCode:ANALYTICS_TRANSFER_ERROR_CODE,
			}
		case ORDER_CONFIRMATION_REQUEST:
			return {
				...state,
				pageLoading: true,
				confirmStatus: ''
			}
		case ORDER_CONFIRMATION_LIGHT_SUCCESS:
			return {
				...state,
				pageLoading: false,
				validating: false,
				orderStatus: action.payload.status,
				priceSubTotal: action.payload.priceSubTotal,
				currency : action.payload.currency,
				pageError: '',
			}
		case ORDER_CONFIRMATION_SUCCESS:
			return {
				...state,
				pageLoading: false,
				validating: false,
				items: action.payload.items,
				ffm: action.payload.ffm,
				summary: action.payload.summary,
				tenders: action.payload.tenders,
				tenderTypes : action.payload?.tenders ? action?.payload?.tenders.map((tender)=> {return tender?.tenderType}): [],
				ccDc: cc_dc_temp,
				sywPoints: syw_points_temp,
				quardsPay: quardsPay_temp,
				leasing: leasing_temp,
				//pageApiError: action.payload.errors,
				// cartId: action.payload.cartId,
				confirmStatus: action.payload.status,
				orderStatus: action.payload.status,
				addlAttrs: action.payload.addlAttrs,
				merchantName:action.payload.merchantName,
				orderConfirmationResponseCode: "true",
				responseData: action.payload,
				cartId:action.payload.cartId,
				txnId: action.payload.txnId,
				orderAuthCode: action.payload.orderAuthCode,
				pageError: '',
				cartResponseData: action.payload
			}
		case FETCH_ORDERCONFIRMATION_SUCCESS_ERROR:
		return {
			...state,
			pageLoading: false,
			validating: false,
			responseData: action.payload,
			orderStatus: action.payload.status,
			confirmStatus: action.payload.status,
			orderConfirmationfailure: '',
			orderConfirmationResponseCode: "true",
			//pageError: action.payload,
			orderConfirmationDataError: action.payload.errors
			
		}
		case ORDER_CONFIRMATION_FAILURE:
			return {
				pageLoading: false,
				validating: false,
				items: '',
				ffm: '',
				summary: '',
				tenders: '',
				ccDc: '',
				sywPoints: '',
				quardsPay: '',
				leasing: '',
				cartId: '',
				isValid: false,
				orderConfirmationResponseCode: "true",
				pageError: action.payload,
				confirmStatus: ''
			}
		case FETCH_APPLYPOINTS_REQUEST:
			return {
				...state,
				pageLoading: true,
				pointApplySuccesfully:false,

			}
		case FETCH_APPLYPOINTS_SUCCESS:
			return {
				...state,
				pageLoading: false,
				summary: action.payload.summary,
				tenders: action.payload.tenders,
				tenderTypes : action.payload.tenders.map((tender)=> {return tender.tenderType}),
				sywPoints: syw_points_temp,
				pageApiError: null,
				applyPointsFailure: '',
				pointApplySuccesfully:true,
				pageError: '',
			}
		case FETCH_APPLYPOINTS_SUCCESS_ERROR:
			return {
				...state,
				pageLoading: false,
				applyPointsFailure: '',
				pageError: '',
				pageApiError: action.payload.errors,
				pointApplySuccesfully:false,
				analyticsAPIErrorCode:ANALYTICS_APPLYPOINTS_ERROR_CODE,

			}
		case FETCH_APPLYPOINTS_FAILURE:
			return {
				...state,
				pageLoading: false,
				pageError: action.payload,
				pointApplySuccesfully:false,
				analyticsAPIErrorCode:ANALYTICS_APPLYPOINTS_ERROR_CODE,
			}
		case FETCH_PLACEORDER_REQUEST:
			return {
				...state,
				pageLoading: true,
				validating: true
			}
		case FETCH_PLACEORDER_SUCCESS:
			return {
				pageLoading: true,
				validating: true,
				orderCompleteResponseCode: action.payload.responseCode,
				placeOrderError: null,
				orderPlacedResponse: action.payload,
				pageError: '',
				pageApiError: null,

			}
		case FETCH_PLACEORDER_SUCCESS_ERROR:
			return {
				...state,
				pageLoading: false,
				validating: false,
				orderCompleteResponseCode: action.payload.responseCode,
				pageError: '',
				pageApiError: action.payload.errors,
				orderPlacedResponse: action.payload,
				analyticsAPIErrorCode:ANALYTICS_PLACEORDER_ERROR_CODE,

			}
		case FETCH_PLACEORDER_FAILURE:
			return {
				//...state,
				pageLoading: false,
				validating: false,
				pageError: action.payload,
				analyticsAPIErrorCode:ANALYTICS_PLACEORDER_ERROR_CODE,
			}

			
        case FETCH_CREDITCARD_REQUEST:
return {
        ...state,
    pageLoading: true,
	validating: true
    
}
    case FETCH_CREDITCARD_SUCCESS:
    return {
    ...state,
    pageLoading: false,
	validating: false,
    ccDc:cc_dc_temp,
    summary: action.payload.summary,
    tenders: action.payload.tenders,
	tenderTypes : action.payload.tenders.map((tender)=> {return tender.tenderType}),
    pageApiError: null,
    pageError: '',
   
}
case FETCH_CREDITCARD_SUCCESS_ERROR:
    return {
    ...state,
     pageLoading: false,
	 validating: false,
     pageApiError:action.payload.errors,
	 pageError: '',
	 analyticsAPIErrorCode:ANALYTICS_CREDITCARD_ERROR_CODE,
}
    case FETCH_CREDITCARD_FAILURE:
return {
    ...state,
    pageLoading: false,
	validating: false,
	pageError: action.payload,
	analyticsAPIErrorCode:ANALYTICS_CREDITCARD_ERROR_CODE,
}
	case ADD_SUBSCRIPTION_REQUEST:
			return {
				...state,
				pageLoading: true,
				pointApplySuccesfully:false,

			}
		case ADD_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				pageLoading: false,
				// summary: action.payload.summary,
				// tenders: action.payload.tenders,
				// sywPoints: syw_points_temp,
				pageApiError: null,
				applyPointsFailure: '',
				pointApplySuccesfully:true,
				pageError: '',
			}
		case ADD_SUBSCRIPTION_SUCCESS_ERROR:
			return {
				...state,
				pageLoading: false,
				applyPointsFailure: '',
				pageError: '',
				pageApiError: action.payload.errors,
				pointApplySuccesfully:false,
				analyticsAPIErrorCode:ANALYTICS_PLACEORDER_ERROR_CODE,

			}
		case ADD_SUBSCRIPTION_FAILURE:
			return {
				...state,
				pageLoading: false,
				pageError: action.payload,
				pointApplySuccesfully:false,
				analyticsAPIErrorCode:ANALYTICS_PLACEORDER_ERROR_CODE,
			}
case GENERATE_PAYMENT_REQUEST:
	return {
		...state,
		pageLoading: false,
		validating: false,
		isValid: false,
		pageError:''
	}

case GENERATE_PAYMENT_SUCCESS:
	return {
		...state,
		pageLoading: false,
		validating: false,
		pmtid : action.payload.pmtid,
		pywid : action.payload.pywid,
    	pageApiError: null,
		isValid: true
	}

case GENERATE_PAYMENT_FAILURE:
	return {
		...state,
		pageLoading: false,
		validating: false,
    	pageApiError: null,
    	pageError: action.payload,
		isValid: false
	}

case GET_SDKCONTENT_REQUEST:
	return {
		...state,
		contentValid:false,
		pageLoading: true,
	}

case GET_SDKCONTENT_SUCCESS:
	return {
		...state,
		contentValid: true,
		pageLoading: false,
		response : action.payload,
    	pageError: null,
	}

case GET_SDKCONTENT_SUCCESS_ERROR:
	return {
		...state,
		contentValid: false,
		pageLoading: false,
		response : '',
    	pageError: true,
	}

case GET_SDKCONTENT_FAILURE:
	return {
		...state,
		contentValid: false,
		pageLoading: false,
		pageError: action.payload
	}

case VALIDATE_EMAIL_REQUEST:
	return {
		...state,
		isEmailValid: '',
		pageLoading: true,
		validating: true
	}
case VALIDATE_EMAIL_SUCCESS:
	return {
		...state,
		isEmailValid: true,
		// pageLoading: false,
		validating: false,
		validateEmailResponse: action.payload
	}
case VALIDATE_EMAIL_SUCCESS_ERROR:
	return {
		...state,
		isEmailValid: false,
		// pageLoading: false,
		validating: false,
		validateEmailResponse:''
		
	}
case VALIDATE_EMAIL_FAILURE:
	return {
		...state,
		isEmailValid: false,
		// pageLoading: false,
		validating: false,
		validateEmailResponse:''
	}
	case DISABLE_BUTTON_CLICK_TRUE:
		return {
			...state,
			isDisableButtonClick : true
		}
	case ENABLE_BUTTON_CLICK_TRUE:
		return {
			...state,
			isDisableButtonClick : false
		}
	case ENABLE_INPUTS:
		return {
			...state,
			inputsDisabled:false,
			continuePaymentButtonDisabled:false,
			applyButtonDisabled:false
		}	
	case DISABLE_INPUTS:
		return {
		  ...state,
			inputsDisabled:true,
			continuePaymentButtonDisabled: action.payload === "continueBtn" ? true :false,
			applyButtonDisabled: action.payload === "applyBtn" ? true :false,
		}	
	case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
		sendEmailResponse:"",
		resendEmailButtonDisabled:true,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailResponse: action.payload,
		resendEmailButtonDisabled:false,
      };
    case VERIFY_EMAIL_FAILURE:
	return {
        ...state,
		sendEmailResponse: action.payload,//change later to action.payload
		resendEmailButtonDisabled:false,
      };
    case VERIFY_EMAIL_SUCCESS_ERROR:	
	  return {
		...state,
		sendEmailResponse:action.payload,
		resendEmailButtonDisabled:false,
	  }	
		default:
			return state
	}
}

export default reducer
