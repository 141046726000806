import React from "react";
import { images } from "../pywImagesComponent/PywImages";
import { headerPyw } from "../pywConstantComponent/PywDesignsConstant";
import PywTealiumAnalytics from "../pywAnalytics/PywTealiumAnalytics";
import AnalyticsWrapper from "../pywAnalytics/AnalyticsWrapper";
const Pyw_PaymentFailed = (props) => {
	return (
		<main className="flex-container">
			{headerPyw()}
			<section class="container container-wrapper container-wrapper-without-padding p-05">
				{/* <PywTealiumAnalytics response={props.pageData} tealiumEvent="pyw_order_failed" /> */}
				<AnalyticsWrapper
					triggerType="page"
					event="pyw_order_failed"
					data={{
						...props?.pageData?.responseData,
						transaction_id: props?.pageData?.responseData?.omsId,
						merchant_transaction_id: props?.pageData?.responseData?.txnId,
					}}
				/>
				<h3 class="center">Sorry. Payment failed.</h3>
				<div class="center">
					<img src={images.closeRed} alt="" />
				</div>
				<div class="card column mt-24 mb-16 pt-24 pb-24">
					<h1 className="light-blue mb-12 medium">
						{props.pageData.summary.currency +
							props.pageData.summary.priceSubTotal}
					</h1>
					<h4>{props.pageData.merchantName}</h4>
				</div>
				<h3 class="center">
					It looks like an error ocurred when
					<br /> processing your payment
				</h3>
				<p class="grey60 center mt-16">Please try again or try another card.</p>
			</section>
			<footer>
				<p class="powered">
					<img src={images.lock} alt="" /> Powered by Shop Your Way
				</p>
			</footer>
		</main>
	);
};
export default Pyw_PaymentFailed;
