import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cookie from "react-cookies";
import {headers,PYWCONFIRMATION} from "../pywConstantComponent/PywConstant";
import { orderConfirmationDetails } from "../redux";
import PywError from"../pywErrorComponent/PywError";
import Pyw_PaymentConfirm from"./Pyw_PaymentConfirm";
import Pyw_PaymentFailed from"./Pyw_PaymentFailed";
import Pyw_PaymentOnHold from"./Pyw_PaymentOnHold";
import { loading } from "../pywConstantComponent/PywDesignsConstant";
import * as duui from'device-uuid'
const Pyw_10112 = (props) => {

    //const { user, isAuthenticated, isLoading } = useAuth0();
    const pageData = useSelector(state => state.pageData);
    const [cartId, setCartId] = useState("");
    const [refID, setrefID] = useState("");
    const [status, setStatus] = useState("");
    const dispatch = useDispatch();
    const [navigate, setNavigate] = useState(false);
    const [intervalId, setIntervalId] = useState("");
    const [durationId, setDurationId] = useState("");
    const [confirmFlag, setConfirmFlag] = useState(false);
   

    // const handleInputChange = () => {
        
    //         clearInterval(intervalId);
    //         clearInterval(durationId);
    //         setConfirmFlag(true);
    //     for (let i = 0; i < 100; i++) {
    //             window.clearInterval(i);
    //         }
       
    // };
    
    const addStatusOnUrl = (statusType) => {
        window.location.replace(window.location.href + "&status=" + statusType);
    };

    useEffect(() => {
        const queryString = require("query-string");
        const values = queryString.parse(props.props.location.search);
        const cartId = values.pywid;
        // const refId = window.opener === null ? encodeURIComponent(values.pywrefid) : values.pywrefid;
        // const refId = values.pywrefid;
        const refid = sessionStorage.getItem("pywrefid")
        const statusType = values.status;

        setStatus(statusType);
        setCartId(cartId);
        setrefID(refid)
        let requestBody = {
        };

        headers.cartId=cartId;
        headers.refid = refid;
		headers.merchantClientId = sessionStorage.getItem("pywmerchant");
        // headers.pywstate = PYWCONFIRMATION+"~~"+cookie.load("at0pyw")+duui.DeviceUUID().get();

        const requestHeaders = {
            headers
        };
        dispatch(orderConfirmationDetails(requestBody, requestHeaders, ""));
        // setIntervalId(setInterval(() => {
        //         dispatch(orderConfirmationDetails(requestBody, requestHeaders, ""));
        //     }, parseInt(process.env.REACT_APP_ORDE_CONFIRM_INTERVAL)));


        // setDurationId(setInterval(() => {
        //         setInterval(handleInputChange());
        //     }, parseInt(process.env.REACT_APP_ORDE_CONFIRM_WAITING_DURATION)));
        
    }, []);

    if (navigate) {
        window.close();
    }else if (pageData.pageError) {
        // if (!confirmFlag) {
        //     handleInputChange();
        // }
        return <PywError pageData={pageData}/>;
    }
    else if (pageData.pageLoading) {
        // if (pageData.confirmStatus !== undefined && pageData.confirmStatus !== ""
        //     && (pageData.confirmStatus !== "interim-order"
        //         || (status !== undefined && status !== ""))) {
        //         handleInputChange();
        //     }
        return (loading())
    }else if (pageData.confirmStatus!==undefined &&pageData.confirmStatus!==""&&pageData.confirmStatus==="confirm") {
        if(pageData.addlAttrs.successPath===undefined || pageData.addlAttrs.successPath==="" || pageData.addlAttrs.returnPath === pageData.addlAttrs.successPath) {            
            if ((status === undefined || status === "")) {
                addStatusOnUrl("confirm")
            } else {
                return (
                    <Pyw_PaymentConfirm pageData={pageData}/>
                )
            }
        }
        else {
            window.location.replace(pageData.addlAttrs.successPath + "?pywid=" + cartId+ "&pywrefid=" + refID  + "&pywmsg=Y");
        }
    } else if (pageData.confirmStatus!==undefined &&pageData.confirmStatus!==""&& pageData.confirmStatus==="interim-order") { 
        if ((status === undefined || status === "")) {
            addStatusOnUrl("onhold")
        }else{
        return ( <Pyw_PaymentOnHold pageData={pageData}/>)
    }
    }else if (pageData.confirmStatus!==undefined &&pageData.confirmStatus!==""&& pageData.confirmStatus==="cart") { 
        if ((status === undefined || status === "")) {
            addStatusOnUrl("failed")
        }else{
        return ( <Pyw_PaymentFailed pageData={pageData}/>)
        }
    }else if (pageData.confirmStatus!==undefined &&pageData.confirmStatus!==""&& pageData.confirmStatus==="cancel") { 
        if ((status === undefined || status === "")) {
            addStatusOnUrl("failed")
        }else{
        return ( <Pyw_PaymentFailed pageData={pageData}/>)
        }
    }else { 
        if ((status === undefined || status === "")) {
            addStatusOnUrl("onhold")
        }else{
        return ( <Pyw_PaymentOnHold pageData={pageData}/>)
    }
    }
}
export default Pyw_10112;