import React from "react";
import postscribe from "postscribe";
import { utag_global_var } from "./Pyw_Utag_Global_Variable";
import { utag_checkout_var } from "./Pyw_Utag_Checkout_Variable";
import { utag_purchase_var } from "./Pyw_Utag_Event_Purchase";
import { utag_placeorder_var } from "./Pyw_Utag_Event_PlaceOrder";

class PywTealiumAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkout_utag_data: {},
            global_utag_data: {},
            purchase_utag_data: {},
            placeorder_utag_data: {},
            initial_utag_data: {},
            linkClick_utag_data: {},
            contactless_utag_data: {},
            paymentstatus_utag_data:{}
        };
    }

    componentDidUpdate() {
        var utag_count = window.utag_update_count
        if(utag_count == 1){
            return;
        }
        var response = this.props.response;
        var event = (this.props.tealiumEvent !== undefined) ? this.props.tealiumEvent : (response.tealiumEvent !== undefined) ? response.tealiumEvent : null;
        var utag__data_script = null;
        
        if (event != null && (event === 'checkout_decline_nonecm' || event === 'checkout_decline_ecm' || event === 'contactless_offerload_decline') && response !== undefined) {
                this.prepareUtagContactless(response,event)
                var txnDetails={
                     "merchant_transaction_id":response.txnId,
                }
                this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data,txnDetails)
                utag__data_script = this.state.contactless_utag_data;
                //removing element from the utag if present
                var e = document.getElementById('utag-container');
                var child = e.lastElementChild; 
                while (child) {
                    e.removeChild(child);
                    child = e.lastElementChild;
                }
        }
        
        if (utag__data_script !== null) {
            this.addCustomDatatoUtag(utag__data_script,this.props);
            utag__data_script = '<script type="text/javascript"> var utag_data =' + JSON.stringify(utag__data_script) + '; </script>';
            var utag_count_script = '<script type="text/javascript"> var utag_update_count =1; </script>'; 
            postscribe("#utag-container", utag_count_script);
            postscribe("#utag-container", utag__data_script);
            var utag_view_script = '<script type="text/javascript"> utag.view(utag_data); </script>';
            postscribe("#utag-container", utag_view_script); 
        }
    }
    componentDidMount() {
        var response = this.props.response;
        var event = (this.props.tealiumEvent !== undefined) ? this.props.tealiumEvent : (response.tealiumEvent !== undefined) ? response.tealiumEvent : null;
        var isError = false;
        if(response !== undefined && response !== null){
            if((response.pageError!==undefined && response.pageError!==null && response.pageError!=="") || (response.pageApiError!==undefined && response.pageApiError!=null && response.pageApiError!=="")){
                isError = true;
            }
        }
        var utag__data_script = null;
        var utag_count = window.utag_data_count
        if (event != null && event === 'checkout' && response !== undefined) {
            var iscartIdPresent = (response.cartId !== undefined && response.cartId !== null && response.cartId !== "");
            if (isError || (iscartIdPresent && utag_count!==1)) {
                //Adding merchant_transaction_id in case of contactless.
                var txnDetails={
                    "merchant_transaction_id": response.txnId
                }
                this.state.checkout_utag_data = Object.assign(this.state.checkout_utag_data, txnDetails);
                this.prepareUtagCheckout(response);
                utag__data_script = this.state.checkout_utag_data;
                var utag_count_script = '<script type="text/javascript"> var utag_data_count =1; </script>';
                postscribe("#utag-container", utag_count_script);
            }

        } 
        else if (event != null && event === 'purchase' && response !== undefined) {
            //Added orderAuthCode for orderConfirmation of contactless
            var authCode={
                "transaction_id":response.orderAuthCode
            }
            if(response.orderAuthCode !== undefined && response.orderAuthCode !== ""){
                this.state.purchase_utag_data = Object.assign(this.state.purchase_utag_data, authCode);
            }
            if(response !== undefined){
                var cartId = response.orderPlacedResponse.cartId !== undefined ? response.orderPlacedResponse.cartId :response.cartId ;
            }
            if(response.responseData !== undefined && response.responseData !== ''){
                response = response.responseData
            }
            
            this.prepareUtagPurchase(response, cartId);
            utag__data_script = this.state.purchase_utag_data;
        }else if (event != null && event === 'place_order') {           
            if(response !== undefined){
                var cartId = response.orderPlacedResponse.cartId !== undefined ? response.orderPlacedResponse.cartId : response.cartId;
            }
            if(response.responseData !== undefined && response.responseData !== ''){
                response = response.responseData;
            }

            this.prepareUtagPlaceOrder(response, cartId);
            utag__data_script = this.state.placeorder_utag_data;
        }else if (event != null && event === 'initial') {           
            this.prepareUtaginitial(response);
            utag__data_script = this.state.initial_utag_data;
        } else if (event != null && event === 'Linkclick' && response !== undefined) {
            //   this.prepareUtagLinkClick(response)
            // utag__data_script = '<script type="text/javascript"> var utag_data =' + JSON.stringify(this.state.linkedClick_utag_data) + '; </script>';
        } else if(event != null && event === 'contactless_offerload' && response !== undefined){
            this.prepareUtagContactless(response,event)
            utag__data_script = this.state.contactless_utag_data;
        } else if(event != null && (event === 'confirm_payment') || (event === 'cancel_payment') || (event === 'onhold_payment')  && response !== undefined){
            this.prepareUtagContactLessPaymentStatus(response,event)
            utag__data_script = this.state.contactless_utag_data;
        } else if(event != null && (event === 'pyw_order_failed') || (event === 'pyw_order_hold') && response !== undefined){
            this.prepareUtagPaymentStatus(response,event)
            utag__data_script = this.state.paymentstatus_utag_data;
        }

        if (utag__data_script !== null) {
            this.addCustomDatatoUtag(utag__data_script,this.props);
            utag__data_script = '<script type="text/javascript"> var utag_data =(' + JSON.stringify( utag__data_script ) + '); </script>';
            postscribe("#utag-container", utag__data_script);            
            var utag_view_script = '<script type="text/javascript"> utag.view(utag_data); </script>';
            postscribe("#utag-container", utag_view_script);
          
        }
    }

    prepareUtagCheckout(response) {
        this.state.global_utag_data = utag_global_var(response);
        this.state.checkout_utag_data = Object.assign(this.state.checkout_utag_data, this.state.global_utag_data)
        var checkout_data = utag_checkout_var(response);
        this.state.checkout_utag_data = Object.assign(this.state.checkout_utag_data, checkout_data)
    }

    prepareUtagPurchase(response, cartId) {
        var cartDetails={
            "cartId":cartId,
        }
        response = Object.assign(response, cartDetails)
        this.state.global_utag_data = utag_global_var(response);
        this.state.purchase_utag_data = Object.assign(this.state.purchase_utag_data, this.state.global_utag_data)
        var checkout_data = utag_purchase_var(response);
        this.state.purchase_utag_data = Object.assign(this.state.purchase_utag_data, checkout_data)
    }

    prepareUtagPlaceOrder(response, cartId) {
        var cartDetails={
            "cartId":cartId,
        }
        response = Object.assign(response, cartDetails)
        this.state.global_utag_data = utag_global_var(response);
        this.state.placeorder_utag_data = Object.assign(this.state.placeorder_utag_data, this.state.global_utag_data)
        var checkout_data = utag_placeorder_var(response);
        this.state.placeorder_utag_data = Object.assign(this.state.placeorder_utag_data, checkout_data)
    }

    prepareUtaginitial(response) {
        var cartDetails={
            
        }
        response = Object.assign(response, cartDetails)
        this.state.global_utag_data = utag_global_var(response);
        this.state.initial_utag_data = Object.assign(this.state.initial_utag_data, this.state.global_utag_data)
        
        var initial_event_details = {
            "eventCategory": 'PYW load',
            "eventAction": 'finish pyw load',
            "eventLabel": 'finished pyw sdk loading',
            "eventName": 'finishpywload',
            "event_name": 'finishpywload',
            "eventValue": "finished pyw loading view"

        }
        this.state.initial_utag_data = Object.assign(this.state.initial_utag_data, initial_event_details)
    }

    prepareUtagContactless(response,event) {
        var txnDetails={
            "merchant_transaction_id":response.txnId,
        }
        this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, txnDetails)
        this.state.global_utag_data = utag_global_var(response);
        this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, this.state.global_utag_data)

        var contactless_offerload_event_details = {
            "eventName": 'load offer',
            "event_name": 'load offer',
            "eventValue": "contactless pyw offer loaded",
            "eventAction": 'pyw offer load',
            "eventCategory": 'pyw offer load',
            "eventLabel": 'pyw offer load'
        }
        var contactless_offerload_decline_event_details = {
            "eventAction":"nonecm-offer-decline",
            "eventCategory":"nonecm-offer-decline",
            "eventLabel":"nonecm-offer-decline",
            "event_name":"nonecm-offer-decline",
            "eventValue":"nonecm-offer-decline"
        }
        var contactless_description_ecm_decline = {
            "eventAction":"ecm-checkout-decline",
            "eventCategory":"ecm-checkout-decline",
            "eventLabel":"ecm-checkout-decline",
            "event_name":"ecm-checkout-decline",
            "eventValue":"ecm-checkout-decline"
        }
        var contactless_description_nonecm_decline = {
            "eventAction":"nonecm-checkout-decline",
            "eventCategory":"nonecm-checkout-decline",
            "eventLabel":"nonecm-checkout-decline",
            "event_name":"nonecm-checkout-decline",
            "eventValue":"nonecm-checkout-decline"
        }

        if(event == 'contactless_offerload'){
            this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, contactless_offerload_event_details);
        }else if(event == 'checkout_decline_ecm'){
            this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, contactless_description_ecm_decline);
            delete this.state.contactless_utag_data.eventName;
        }else if(event == 'checkout_decline_nonecm'){
            this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, contactless_description_nonecm_decline);
            delete this.state.contactless_utag_data.eventName;
        } else if(event == 'contactless_offerload_decline'){
            this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, contactless_offerload_decline_event_details);
            delete this.state.contactless_utag_data.eventName;
        }
    }


    prepareUtagContactLessPaymentStatus(response,event){
        var txnDetails={
            "merchant_transaction_id":response.txnId,
        }
        this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, txnDetails)
        this.state.global_utag_data = utag_global_var(response);
        this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, this.state.global_utag_data)
        var confirm_event_details = {
            "eventName": 'confirm payment',
            "event_name": 'confirm payment',
            "eventValue": "payment confirmed",
            "eventAction": 'confirm payment',
            "eventCategory": 'confirm',
            "eventLabel": 'confirm payment'
        }
        var cancel_event_details = {
            "eventName": 'cancel payment',
            "event_name": 'cancel payment',
            "eventValue": "payment cancelled",
            "eventAction": 'cancel payment',
            "eventCategory": 'cancel',
            "eventLabel": 'cancel payment'
        }
        var onhold_event_details = {
            "eventName": 'hold payment',
            "event_name": 'hold payment',
            "eventValue": "payment onhold",
            "eventAction": 'hold payment',
            "eventCategory": 'onhold',
            "eventLabel": 'hold payment'
        }

        if(event == 'confirm_payment')
            this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, confirm_event_details);
        else if(event == 'cancel_payment')
            this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, cancel_event_details);
        else if(event == 'onhold_payment')
            this.state.contactless_utag_data = Object.assign(this.state.contactless_utag_data, onhold_event_details)
    }

    prepareUtagPaymentStatus(response,event){
        var txnDetails={
            "merchant_transaction_id":response.txnId,
        }
        this.state.paymentstatus_utag_data = Object.assign(this.state.paymentstatus_utag_data, txnDetails)
        this.state.global_utag_data = utag_global_var(response);
        this.state.paymentstatus_utag_data = Object.assign(this.state.paymentstatus_utag_data, this.state.global_utag_data)

        var failed_event_details = {
            "event_name" : "pyw_order_failed",
            "eventName": "pyw_order_failed",
            "eventAction": "pyw_order_failed",
            "eventCategory": "pyw_order_failed",
            "eventLabel": "order failed",
            "eventValue": "order failed"
        }
        var onhold_event_details = {
            "event_name" : "pyw_order_hold",
            "eventName": "pyw_order_hold",
            "eventAction": "pyw_order_hold",
            "eventCategory": "pyw_order_hold",
            "eventLabel": "order on-hold",
            "eventValue": "order on-hold",
        }

        if(event == 'pyw_order_failed')
            this.state.paymentstatus_utag_data = Object.assign(this.state.paymentstatus_utag_data, failed_event_details);
        else if(event == 'pyw_order_hold')
            this.state.paymentstatus_utag_data = Object.assign(this.state.paymentstatus_utag_data, onhold_event_details);
    }

    addCustomDatatoUtag(data, props){
        Object.keys(props).forEach(val => {
            if(val !== 'response' && val !== 'tealiumEvent'){
                data = Object.assign(data,{[val]:props[val]})
                console.log(val + props[val]);
            }
        });
    }

    render() {
        return <div id="utag-container" />;
    }
}

export default PywTealiumAnalytics;