import React, { useState, useEffect } from "react";
import { images } from "../../pywImagesComponent/PywImages";
import PywTealiumAnalytics from "../../pywAnalytics/PywTealiumAnalytics";
import { Redirect } from "../../pywUtilComponent/PywRedirect.ts";
import {
  handleChange,
  parseDollarValue,
} from "../../pywUtilComponent/PywUtil.js";
import {
  PYWSUMMARY,
  SUBSCRIPTION,
} from "../../pywConstantComponent/PywConstant";
import {
  SAVECC,
  FULL_PAY_POINTS,
  LOGINREQUEST,
} from "../../pywConstantComponent/PywSummaryMessageConstant";
import cookie from "react-cookies";
import postscribe from "postscribe";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPrepareDetails,
  fetchPlaceOrder,
  fetchPlaceOrderAndUpdateCard,
  addSubscription,
} from "../../redux";
import { useAuth0 } from "@auth0/auth0-react";
import PywError from "../../pywErrorComponent/PywError";
import Payment from "payment";
import * as duui from "device-uuid";
import {
  formatCVC,
  formatExpirationDate,
} from "../../pywUtilComponent/PywCardUtil";
const PywCommonSummary = (props) => {
  const pageData = useSelector((state) => state.pageData);
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const [saveCcardId, setSaveCcardId] = useState("");
  const { user , loginWithRedirect} = useAuth0();
  const [userInfo, setUserInfo] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const [cvvReq, setCvvReq] = useState("");
  const [expiryReq, setExpiryReq] = useState("");
  const [cvvError, setCvvError] = useState("");
  const onClickStopHandller = (e) => {
    e.stopPropagation();
  };

  const handleInputChange = ({ target }) => {
    if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      setExpiry(target.value);
      if (!Payment.fns.validateCardExpiry(target.value)) {
        setExpiryError("Enter the valid expiry date");
      } else {
        setExpiryError("");
      }
    } else if (target.name === "cvv") {
      target.value = formatCVC(target.value, "mastercard");
      setCvv(target.value);
      if (!Payment.fns.validateCardCVC(target.value, "mastercard")) {
        setCvvError("Enter valid CVV");
      } else {
        setCvvError("");
      }
    }
  };

  const placeOrderHandller = () => {
    const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform: "PYW",
    };
    headers.cartId = sessionStorage.getItem("pywcartId");
    headers.merchantClientId = sessionStorage.getItem("pywmerchant");
    headers.refid = sessionStorage.getItem("pywrefid");
    const requestOptions = {
      headers,
    };
    let ccNumber = "";
    let ccExp = "";
    let cchsape = "";
    let ccName = "";
    let ccType = "";
    let ccCvvreq = "";
    let ccExpiryreq = "";
    let postalCode = "";
    let resCountryCode = "";
    let ffmZipCode = "";
    let cvvValue =
      document.getElementById("cvv") != null &&
        document.getElementById("cvv") != undefined
        ? document.getElementById("cvv").value
        : "";
    let expiryValue =
      document.getElementById("expiry") != null &&
        document.getElementById("expiry") != undefined
        ? document.getElementById("expiry").value
        : "";

    {
      pageData.ffm.map((addresinfo) => {
        if (
          addresinfo.address !== undefined &&
          addresinfo.address.length !== 0
        ) {
          ffmZipCode = addresinfo.address.zipCode;
        }
      });
    }
    if (saveCcardId !== "") {
      {
        pageData?.ccDc?.savedCards.map((tender) => {
          if (saveCcardId === tender.paymentCardId) {
            ccNumber = tender.panLastFour;
            ccExp = tender.expiryDate;
            ccName = tender.nameOnCard;
            cchsape = tender.paymentSnapshotId;
            ccType = tender.cardType;
            ccCvvreq = tender.cvvRequired;
            ccExpiryreq = tender.expiryRequired;
            postalCode =
              tender.address !== undefined
                ? tender.address.postalCode
                : ffmZipCode;
            resCountryCode =
              tender.address !== undefined ? tender.address.country : "";
          }
        });
      }
    }
    if (
     (!pageData.tenderTypes.includes("CC_DC") || parseFloat(pageData.ccDc.amountPayable) === 0.0 &&
      (pageData.addlAttrs?.payType === undefined ||
        !pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION)))
    ) {
      dispatch(fetchPlaceOrder(requestOptions));
    } else if (
      (parseFloat(pageData.ccDc.amountPayable) !== 0.0 ||
        (pageData.addlAttrs?.payType !== undefined &&
          pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION))) &&
      saveCcardId !== "" &&
      ((ccCvvreq === "Y" && cvvError === "" && cvvValue !== "") ||
        (ccCvvreq === "N" && cvvError === "" && cvvValue === "")) &&
      ((ccExpiryreq === "Y" && expiryError === "" && expiryValue !== "") ||
        (ccExpiryreq === "N" && expiryError === "" && expiryValue === ""))
    ) {
      const requestBody = {
        payment: [
          {
            paymentType: "creditCard",
            creditCard: {
              cardNumber: ccNumber,
              paymentCardId: saveCcardId,
              paymentSnapshotId: cchsape,
              creditCardType: ccType,
              expiryDate:
                ccExpiryreq === "Y" ? expiry.replace("/", "20") : ccExp,
              cvvNumber: ccCvvreq === "Y" ? cvv : "",
              //expiryDate: ccExp,
              //cvvNumber: "",
              cardHolderName: ccName,
              streetAddress: "USA",
              zipCode: postalCode, //"45321",
              countryCode: "US", //resCountryCode,
              currency: "USD",
            },
          },
        ],
      };
      //TODO if for subscription else current follow
      if (
        pageData.addlAttrs?.payType !== undefined &&
        pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION)
      ) {
        let itemId = "";
        {
          pageData.items.map((item) => {
            itemId = item.itemId;
          });
        }
        var requestSubscription = {
          subscriptions: [
            {
              itemId: itemId,
              subscription: {
                id: pageData.addlAttrs?.payType.replace(SUBSCRIPTION, ""),
              },
            },
          ],
        };

        dispatch(
          addSubscription(requestOptions, requestBody, requestSubscription)
        );
      } else {
        dispatch(fetchPlaceOrderAndUpdateCard(requestOptions, requestBody));
      }
    } else {
      if (ccCvvreq === "Y" && cvvError === "" && cvvValue === "") {
        setCvvError("Enter valid CVV");
      }
      if (ccExpiryreq === "Y" && expiryError === "" && expiryValue === "") {
        setExpiryError("Enter the valid expiry date");
      }
    }
  };
  // useEffect(() => {
  //   // const userJson = JSON.stringify(user);
  //   // const userNew = userJson.split(process.env.REACT_APP_CLAIMS_URL).join("");
  //   // const user_json = JSON.parse(userNew);
  //   // setUserInfo(user_json);
  //   const refid = sessionStorage.getItem("pywrefid");
  //   const items = [];
  //   let requestBody = { items };
  //   const headers = {
  //     Accept: "Application/json",
  //     "Content-Type": "application/json",
  //     channel: "ONLINE",
  //     platform: "PYW",
  //   };
  //   headers.cartId = sessionStorage.getItem("pywcartId");
  //   headers.refid = sessionStorage.getItem("pywrefid");
  //   headers.merchantClientId = sessionStorage.getItem("pywmerchant");

  //   headers.pywstate =
  //     PYWSUMMARY + "~~" + sessionStorage.getItem("at0pyw") + duui.DeviceUUID().get();
  //   const requestHeaders = {
  //     headers,
  //   };
  //   var rtocken =
  //     '<script type="text/javascript" src="https://r0.shopyourway.com/w/profile"> </script>';
  //   postscribe("#rtockenpost", rtocken);
  //   dispatch(fetchPrepareDetails(requestBody, requestHeaders, refid));
  // }, []);
  return (
    <div>
      {
        pageData?.tenderTypes?.includes("CC_DC") ? <>
        {(() => {
       if (parseFloat(pageData.summary.paymentDue) !== 0 || (pageData.addlAttrs?.payType!==undefined && pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION))) {
     return (<> {pageData.cartResponseData?.summary?.sywr?.isExistingMember === 'Y' ? LOGINREQUEST(pageData, loginWithRedirect, dispatch) : SAVECC(pageData)} 
     <button
       className="ECM-selection-card selected pt-24 pr-16 pl-16 pb-24"
       id="checkedSaveCard"
       onClick={e => { handleChange(e) } }
     >
       <div className="ECM-allign summary-card-img">
         <label className="checkbox ECM-allign mt-0-5">
           <input type="checkbox" defaultChecked name="" id="option-1" />
           <span className="check-icon mr-6">
             <img src={images.check} alt="" />
           </span>
         </label>
         <img className="ml-10" src={images.creditcardplane} alt="" />
       </div>
       <div className="description">
         <h4 className="fs-16 pr-36 hs-shsdesc-decline lh-20">
           Shop Your Way Mastercard®
         </h4>

         {(() => {
                   if (pageData?.ccDc?.savedCards !== undefined && pageData?.ccDc?.savedCards?.length >= 0) {
                       if (saveCcardId === "")
                       setSaveCcardId(pageData?.ccDc?.savedCards[0].paymentCardId)
                       return (
                       <p className="dark-grey card-digits mb-16 fw-400 fs-14 lh-20">ending in {pageData?.ccDc?.savedCards[0].panLastFour}</p>
                       );
                   }
                   })() }          
                   {(() => {
                   if (pageData?.ccDc?.savedCards !== undefined && pageData?.ccDc?.savedCards?.length >= 0 &&pageData?.ccDc?.savedCards[0].cvvRequired==="Y") {
                       if (saveCcardId === "")
                       setSaveCcardId(pageData?.ccDc?.savedCards[0].paymentCardId)
                       if(cvvReq==="")
                       setCvvReq(pageData?.ccDc?.savedCards[0].cvvRequired)  
                       return (<div className="cvv-line mb-12">
                       <div className="cvv-input" >
                           <label className="input-label mr-16" onClick={onClickStopHandller} >
                           <input name="cvv" required onChange={handleInputChange}
                               className="input-text dark-grey fs-14"
                               id= "cvv"
                               onClick={onClickStopHandller}  />
                           <span className="label-span title-message-grey fw-400 fs-13_5">cvv</span>
                           </label>
                           <p className="fs-12 fw-400 lh-16 title-grey">Please copy your cvv code from the back of your card.</p>
                       </div>
                       <small className="orange show half mb-2 mt-0" >{cvvError}</small>
                       </div>
                       );
                   }
                   })() }
                   {(() => {
                   if (pageData?.ccDc?.savedCards !== undefined && pageData?.ccDc?.savedCards?.length >= 0
                       && pageData?.ccDc?.savedCards[0].expiryRequired==="Y") {
                       if (saveCcardId === "")
                       setSaveCcardId(pageData?.ccDc?.savedCards[0].paymentCardId)
                       if(expiryReq==="")
                       setExpiryReq(pageData?.ccDc?.savedCards[0].expiryRequired)
                       return (<div className="expiry-line mb-12">
                       <div className="expiry-input">
                           <label className="input-label mr-16" onClick={onClickStopHandller} >
                           <input name="expiry" required
                               onChange={handleInputChange}
                               className="input-text dark-grey fs-14"
                               id="expiry"
                               onClick={onClickStopHandller} />
                               <span className="label-span title-message-grey fw-400 fs-13_5">mm/yy</span>
                           </label>
                           <p className="fs-12 fw-400 lh-16 title-grey">Please copy your expiry date from the front of your card.</p>
                       </div>
                       <small className="orange  show half mb-2 mt-0" >{expiryError}</small>
                       </div >
                       );
                   }
                   })() }

         {(() => {
                   if (pageData.summary.earnPointSummary !== undefined && parseInt(pageData.summary.earnPointSummary.totalEarnPoints) > 0) {
                       return (<p className="dark-grey fw-400 fs-14 lh-20">Earn <span className="semi-light-blue">{(new Intl.NumberFormat('en-US').format((pageData.summary.earnPointSummary.totalEarnPoints)))}</span> Shop Your Way points with this purchase!</p>)
                   } else return (<p className="dark-grey">Earn <span className="semi-light-blue">{new Intl.NumberFormat('en-US').format(parseInt(parseFloat(pageData.summary.paymentDue) * parseInt(20)))}</span> Shop Your Way points with this purchase!</p>)
                   })() }
       </div>
     </button></>)}
      })() }
      {(() => {
                      if (parseFloat(pageData.summary.paymentDue) === 0 && (pageData.addlAttrs?.payType===undefined || !pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION))) {

                        return (<div>
                          {FULL_PAY_POINTS}
                          <button className="ECM-selection-card  pt-24 pr-16 pl-16" disabled>
                            
                          <div className="ECM-allign ">
                            <label className="checkbox">
                              <input type="checkbox" disabled name="" id="option-1" />
                              <span className="check-icon mr-18 mt-8">
                                <img src={images.check} alt="" />
                              </span>
                            </label>
                            <img src={images.creditcardplane} alt="" />
                            </div>
                            <div className="description ">
                              <h4 className="title-grey pr-36">Shop Your Way Mastercard<sup className="sup-without-fonts">®</sup></h4>

                              {(() => {
                                if (pageData?.ccDc?.savedCards !== undefined && pageData?.ccDc?.savedCards?.length > 0) {
                                  if (saveCcardId === "")
                                    setSaveCcardId(pageData?.ccDc?.savedCards[0].paymentCardId)
                                  return (
                                    <p className="grey60 mb-12">ending in {pageData?.ccDc?.savedCards[0].panLastFour}</p>
                                  );
                                }
                              })() }

                            </div>
                          </button>
                        </div>
                        );
                      }
        })() }

        {(() => {
            if (parseDollarValue(pageData?.sywPoints?.currentPointsBalance) < parseFloat(pageData.summary.tenderAmountDue)) {
                return <p className="orange-updated alert fs-14 insufficient-points-msg fw-700" id="alert">
                You have insufficient points to make this purchase. Use your Shop Your Way Mastercard<sup className="sup-without-fonts">®</sup> to complete the purchase.</p>
            }
            else {
                return <p className="orange-updated alert fs-14 insufficient-points-msg insufficient-points-msg__syw fw-700 lh-20" id="alert">Insufficient Points selected. Use your Shop Your Way points and/or Shop Your Way Mastercard<sup className="sup-without-fonts">®</sup> to complete this purchase.</p>
            }
        })()}
        </> : <div className="tender-body"></div>
      }
       
      <footer className="container-wrapper-without-padding member-center-card btn-summary-continue mb-30 pb-0 pt-28">
        {(() => {
                    if ((parseFloat(pageData.summary.tenderAmountDue) === 0 && (
                      pageData.addlAttrs?.payType=== undefined || !pageData.addlAttrs?.payType.startsWith(SUBSCRIPTION)) )
                      || saveCcardId !== ''
                      && ((cvvReq==="Y"&&cvvError==='' && cvv!=='') ||((cvvReq==="N" ||cvvReq==="")&&cvvError==='' && cvv===''))
                      && ((expiryReq==="Y" && expiryError===''&& expiry!=='') ||((expiryReq==="N" || expiryReq==="") && expiryError===''&& expiry===''))) {
                      return (<button className="btn btn-primary member-footer mt-20" id="payButton"  onClick={placeOrderHandller}><b className="fw-700 fs-16 lh-20">Complete Payment</b></button>);
                    } else {
                      return (<button className="btn btn-primary member-footer h-48 mt-20 summary-payment-btn-disabled" id="payButton"><b className="fw-700 fs-16 lh-20">Complete Payment</b></button>);
                    }
        })() }
        {/* <button className="btn btn-primary member-footer mt-20" id="payButton">
          <b>Complete Payment</b>
        </button> */}
      </footer>
    </div>
  );
};

export default PywCommonSummary;
