import React from "react";
import {handleClickErrorBanner } from "../pywUtilComponent/PywUtil.js";
import {images} from '../pywImagesComponent/PywImages';
import { Redirect } from "react-router-dom";
import {CCERRORCODES, ERRORCODES} from "../pywConstantComponent/PywConstant"
import '../assets/styles/css/main.css';
const PywError = (props) => {

  return (
    <div>
      {(() => {
        if (props.pageData.pageError !== undefined && props.pageData.pageError !== '') {
          return <Redirect to="/pyw/error"/>
        }
      })() }
      {(() => {
        if (props.pageData.pageApiError !== undefined && props.pageData.pageApiError !== null && props.pageData.pageApiError !== '' ) {
          return (
            props.pageData.pageApiError.map(error => {
              if (ERRORCODES.some(arrVal => (arrVal === error.type || arrVal === error.code))) {
                return <Redirect to="/pyw/error"/>
              }
              else if(CCERRORCODES.some(arrVal => (arrVal === error.type || arrVal === error.code) && (props.pageName === "customSummary"))) {
                return <Redirect to="/pyw/cc/error"/>
              }
              return (
                <div class="error-banner">
                  <p>{error.message} </p>
                  <button
                    id="hideBanner"
                    onClick={e => {
                      handleClickErrorBanner(e);
                    } }
                    >
                    <img src={images.closewhite} alt="" />
                  </button>
                </div>
              );
            })
          );
        }
      })() }
    </div>
  );
}

export default PywError;
